import React, { useEffect, useState } from "react";
import DPPAOption from "../../../../domain/entities/usersettings/DPPAOption";
import GLBAOption from "../../../../domain/entities/usersettings/GLBAOption";
import RegistrationResponse from "./RegistrationResponse";
import GLBADPPAChange from "./GLBADPPAChange";
import { Col, Row, Table } from "react-bootstrap";
import CompanyNameModel from "../../../../domain/entities/admin/models/CompanyNameModel";
import Question from "../../../../domain/entities/usersettings/Question";

type Props = {
    details: any;
    GLBAOptions: GLBAOption[];
    DPPAOptions: DPPAOption[];
    companies: CompanyNameModel[];
    questions: Question[];
}

type PropertyMapping = {
    displayName: string,
    getOldValue: (details: any) => any,
    getNewValue: (details: any) => any,
}

const UserInfoChange: React.FC<Props> = ({ details, GLBAOptions, DPPAOptions, companies, questions }: Props) => {

    const propMappings: { [prop: string]: PropertyMapping } = {
        "FirstName": { displayName: "First Name", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "LastName": { displayName: "Last Name", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "PhoneNumber": { displayName: "Phone Number", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "AlternativeFirstName": { displayName: "Alternative First Name", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "AlternativeLastName": { displayName: "Alternative Last Name", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "AlternativeEmail": { displayName: "Alternative Email", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "AlternativePhoneNumber": { displayName: "Alternative Phone Number", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "SecurityQuestionId": { displayName: "Security Question", getOldValue: d => questions.find(q => q.id == d["OldValue"])?.text , getNewValue: d => questions.find(q => q.id == d["NewValue"])?.text },
        "SecurityQuestionAnswer": { displayName: "Security Question Answer", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "SecondaryEmail": { displayName: "Secondary Email", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "ManagerName": { displayName: "Manager Name", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "EmployerName": { displayName: "Employer Name", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "Address": { displayName: "Employer Address", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "City": { displayName: "Employer City", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "State": { displayName: "Employer State", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "ZipCode": { displayName: "Employer ZIP Code", getOldValue: d => d["OldValue"], getNewValue: d => d["NewValue"] },
        "CompanyId": { displayName: "Company", getOldValue: d => companies.find(c => c.id == d["OldValue"])?.name , getNewValue: d => companies.find(c => c.id == d["NewValue"])?.name},
        "GLBAOptionId": { displayName: "GLBA", getOldValue: d => GLBAOptions.find(o => o.id == d["OldValue"])?.text, getNewValue: d => GLBAOptions.find(o => o.id == d["NewValue"])?.text },
        "DPPAOptionId": { displayName: "DPPA", getOldValue: d => DPPAOptions.find(o => o.id == d["OldValue"])?.text, getNewValue: d => DPPAOptions.find(o => o.id == d["NewValue"])?.text },
    }

    const properties: any[] = details["ChangedProperties"];
    return (
        <Table borderless>
            <thead>
                <tr>
                    <th></th>
                    <th><b>Old</b></th>
                    <th><b>New</b></th>
                </tr>
            </thead>
            <tbody>
                {properties.map(p => {
                    const mapping = propMappings[p["Property"]];
                    return <tr key={Math.random()}>
                        <td><b> {mapping?.displayName ?? p["Property"]}</b></td>
                        <td>{mapping?.getOldValue(p) ?? p["OldValue"]}</td>
                        <td>{mapping?.getNewValue(p) ?? p["NewValue"]}</td>
                    </tr>
                }
                )}
            </tbody>
        </Table>
    );
};

export default UserInfoChange;
