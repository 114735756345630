import { Subject } from "rxjs";
import Result from "../../../domain/common/Result";
import SearchFilterModel from "../../../domain/entities/search/SearchFilterModel ";
import SearchListInputModel from "../../../domain/entities/search/SearchListInputModel";
import CaseRepository from "../../../domain/repositories/cases/ICaseRepository";
import IBaseViewModel from "../IBaseViewModel";

export default class CaseListViewModel implements IBaseViewModel {
  public pageNumber: number;
  public pageSize: number;
  public totalCount: number;
  public searchText: string;
  public pageData: [];
  public showAddModal: boolean;
  public selectedItemId: string | null;
  public isLoading: boolean;
  public isShowError: boolean;
  public errorMessages: Array<string>;

  private timer: any;
  public subject?: Subject<any>;
  public topic?: string;
  public sortDirection?: number;
  public sort: number;
  public filter: SearchFilterModel;

  private caseRepository: CaseRepository;

  public constructor(caseRepository: CaseRepository) {
    this.searchText = "";
    this.selectedItemId = null;
    this.pageNumber = 0;
    this.pageSize = 10;
    this.totalCount = 0;
    this.pageData = [];
    this.showAddModal = false;

    this.isLoading = false;
    this.isShowError = false;
    this.errorMessages = [];
    this.sort = 1;
    this.filter = new SearchFilterModel();
    this.caseRepository = caseRepository;
  }

  attachSubject(subject: Subject<any>, topicName: string): void {
    this.subject = subject;
    this.topic = topicName;
  }

  public onSearchTextQueryChanged = (e: React.FormEvent): void => {
    const input = e as React.FormEvent<HTMLInputElement>;
    this.searchText = input.currentTarget.value;
    this.notifyViewAboutChanges();
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.getPage(this.pageNumber);
    }, 1000);
  };

  public onSortQueryChanged = (e: React.FormEvent): void => {
    const input = e as React.FormEvent<HTMLInputElement>;
    this.sortDirection = +input.currentTarget.value;
    this.notifyViewAboutChanges();
    this.getPage(this.pageNumber);
  };
  onDatesQueryChanged = (value: any) => {
    this.filter.CreationDateFrom = value !== null ? value[0] : undefined;
    this.filter.CreationDateTo = value !== null ? value[1] : undefined;
    if (!this.filter.CreationDateFrom || !this.filter.CreationDateTo || isNaN(this.filter.CreationDateFrom.getTime())
      || isNaN(this.filter.CreationDateTo.getTime())
      || this.filter.CreationDateFrom.getFullYear() < 2020 || this.filter.CreationDateTo.getFullYear() < 2020)
      return;
    this.notifyViewAboutChanges();
    this.getPage(this.pageNumber);
  };

  public getPage = async (page: number): Promise<Result> => {
    try {
      this.pageNumber = page;
      const model = {} as SearchListInputModel;
      model.PageNumber = page;
      model.PageSize = this.pageSize;
      model.SearchText = this.searchText;
      model.Sort = this.sort;
      model.SortDirection = this.sortDirection;
      model.Filter = this.filter;
      this.isLoading = true;
      this.notifyViewAboutChanges();
      const result = await this.caseRepository.get(model);
      if (result.isSuccess) {
        this.pageData = result.value.pageData;
        this.totalCount = result.value.totalCount;
      }
      this.isLoading = false;
      this.isShowError = !result.isSuccess;
      this.errorMessages.push(result.error);
      this.notifyViewAboutChanges();
      return result;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
      return Result.Fail(e.message, 500);
    }
  };

  public openAddModal = (id: string | null) => {
    this.showAddModal = true;
    this.selectedItemId = id;
    this.notifyViewAboutChanges();
  };

  public closeAddModal = () => {
    this.showAddModal = false;
    this.notifyViewAboutChanges();
  };

  private notifyViewAboutChanges = (): void => {
    const data = {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
      totalCount: this.totalCount,
      pageData: this.pageData,
      showAddModal: this.showAddModal,
      selectedItemId: this.selectedItemId,
      isLoading: this.isLoading,
      isShowError: this.isShowError,
      errorMessages: this.errorMessages,
      searchText: this.searchText,
      sort: this.sort,
      sortDirection: this.sortDirection,
      filter: this.filter,
    };
    this.subject?.next({ topic: this.topic, data });
  };
}
