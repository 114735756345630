import React from 'react';
import { Col, Modal, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import BootstrapTable, { ColumnDescription } from "react-bootstrap-table-next";
import { TailSpin } from "react-loader-spinner";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import { useEffect, useState } from "react";
import paginationFactory from "react-bootstrap-table2-paginator";
import PendingRequestModel from "../../../domain/entities/admin/models/PendingRequestModel";
import PendingRequestsViewModel from "../../view-model/admin/PendingRequestsViewModel";
import GlbaDppaChangeRequestDetails from "../../components/admin/GlbaDppaChangeRequestDetails";
import ViewPermissionRequestDetails from "../../components/admin/ViewPermissionRequestDetails";
import PendingRequestType from '../../../domain/entities/admin/enums/PendingRequestType';
import PendingUserRequestApi from '../../../data/admin/PendingUserRequestApi';
import { useDispatch } from 'react-redux';
import { SetUpdateRequestsCount } from '../../../infrastructure/redux/actions/SetUpdateRequestsCount';


type UpdateRequests = {
    pendingRequests: PendingRequestModel[];
    selectedIndex: number;
    pageIndex: number;
    pageSize: number;
    totalCount: number;
    isLoading: boolean;
    isShowError: boolean;
    errorMessages: string[];
    selectedRequest: PendingRequestModel;
    isShowGlbaDppaDetailsModal: boolean;
    isShowViewPermissionDetailsModal: boolean;
    isShowApprovalModal: boolean;
    isShowDenialModal: boolean;
};


const UpdateRequests: React.FC = () => {

    const { viewModel, subscription } = useViewModel(PendingRequestsViewModel, [
        new PendingUserRequestApi(new HttpClient()),
    ]);

    const [state, setState] = useState<UpdateRequests>({
        pendingRequests: viewModel.pendingRequests,
        selectedIndex: 0,
        pageIndex: viewModel.pageIndex,
        pageSize: viewModel.pageSize,
        totalCount: viewModel.totalCount,
        isLoading: viewModel.isLoading,
        isShowError: viewModel.isShowError,
        errorMessages: viewModel.errorMessages,
        selectedRequest: viewModel.selectedRequest,
        isShowGlbaDppaDetailsModal: false,
        isShowViewPermissionDetailsModal: false,
        isShowApprovalModal: false,
        isShowDenialModal: false
    });

    useEffect(() => {
        const subscriber = subscription.subscribe((d: any) => {
            setState({
                ...state,
                ...d.data,
            });
        });

        viewModel.getData();

        return () => {
            subscriber.unsubscribe();
        };
    }, []);

    const dispatch = useDispatch();

    const onConfirmDenial = async () => {
        const res = await viewModel.onConfirmDenial();
        res.isSuccess ? dispatch(SetUpdateRequestsCount(viewModel.pendingRequests.length)) : null;
    }

    const onConfirmApproval = async () => {
        const res = await viewModel.onConfirmApproval();
        res.isSuccess ? dispatch(SetUpdateRequestsCount(viewModel.pendingRequests.length)) : null;
    }

    const columns: ColumnDescription[] = [
        {
            dataField: "requestType",
            text: "Request Type",
            formatter: (cell, row, i) => (
                <span className="username-link" onClick={() => viewModel.onEmailClick(row)}>{
                    row.requestType == PendingRequestType.ViewingOtherUsers ? "Viewing other users" : 
                    row.requestType == PendingRequestType.GlbaDppaPermissions ? "GLBA/DPPA Permissions" : "-" }</span>
            ),
        },
        {
            dataField: "email",
            text: "Email",
        },
        {
            dataField: "employerName",
            text: "Employer Name",
        },
        {
            dataField: "requestDate",
            text: "Request Date",
            formatter: (cell, row) => new Date(row.requestDate).toLocaleDateString(),
        },
        {
            dataField: "id",
            text: "Actions",
            formatter: (cell, row, i) => (
                <div className="account-request-btns d-flex">
                    <button className="btn previous-btn" onClick={() => viewModel.showDenialModal(row)}> Deny</button >
                    <button className="btn next-btn" onClick={() => viewModel.showApprovalModal(row)}> Approve</button>
                </div>
            ),
        },
    ];


    return (
        <div className="container">
            <Helmet>
                <title>Social E-Profiler - Search List</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            <div className="row mt-5">
                <div className="admin-page-title">Update Requests</div>
                <div className="table-style mt-4">
                    <BootstrapTable
                        keyField="searchId"
                        columns={columns}
                        data={state.pendingRequests}
                        bordered={false}
                        headerClasses="header-tr"
                        remote
                        pagination={paginationFactory({
                            pageStartIndex: 1,
                            page: state.pageIndex + 1,
                            sizePerPage: state.pageSize,
                            totalSize: state.totalCount,
                            firstPageText: "First",
                            prePageText: "Back",
                            nextPageText: "Next",
                            lastPageText: "Last",
                            nextPageTitle: "First page",
                            prePageTitle: "Pre page",
                            firstPageTitle: "Next page",
                            lastPageTitle: "Last page",
                            onSizePerPageChange: viewModel.onPageSizeChange,
                            sizePerPageList: [
                                { text: "10", value: 10},
                                { text: "25", value: 25},
                                { text: "50", value: 50},
                                { text: "100", value: 100},
                              ]
                        })}
                        onTableChange={viewModel.onTableChange}
                    />
                </div>
            </div>
            <Modal show={state.isShowGlbaDppaDetailsModal}>
                <Modal.Body className="modal-card user-details-modal-card">
                    <Row className="cancel-modal-header">
                        <Col ><span className="modal-title">Request Info</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={viewModel.hideGlbaDppaDetailsModal}>x</span></Col>
                    </Row>
                    <GlbaDppaChangeRequestDetails glbaDppaChangeRequest={state.selectedRequest} />
                </Modal.Body>
            </Modal>
            <Modal show={state.isShowViewPermissionDetailsModal}>
                <Modal.Body className="modal-card user-details-modal-card">
                    <Row className="cancel-modal-header">
                        <Col ><span className="modal-title">Request Info</span></Col>
                        <Col md="auto" className="close-modal-icon"><span onClick={viewModel.hideViewPermissionDetailsModal}>x</span></Col>
                    </Row>
                    <ViewPermissionRequestDetails searchRequestViewRequest={state.selectedRequest} />
                </Modal.Body>
            </Modal>
            <Modal show={state.isShowApprovalModal}>
                <Modal.Body className="modal-card approve-account-modal-card">
                    <div className="approve-account-modal">
                        <Row className="approve-modal-header">
                            <Col className="p-0"><span className="cancel-modal-title">Approve Request</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={viewModel.hideApprovalModal}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="cancel-modal-body p-0">Are you sure you want to approve this request?</p>
                        </Row>
                        <Row className="modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={viewModel.hideApprovalModal} className="btn previous-btn">Cancel</a></Col>
                            <Col className="p-0"><a onClick={onConfirmApproval} className="btn next-btn">Approve</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={state.isShowDenialModal}>
                <Modal.Body className="modal-card approve-account-modal-card">
                    <div className="approve-account-modal">
                        <Row className="approve-modal-header">
                            <Col className="p-0"><span className="cancel-modal-title">Deny Request</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={viewModel.hideDenialModal}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="cancel-modal-body p-0">Are you sure you want to deny this request?</p>
                        </Row>
                        <Row className="deny-modal-btn-row">
                            <Col md="auto" className="p-0"><a onClick={viewModel.hideDenialModal} className="btn previous-btn">Cancel</a></Col>
                            <Col className="p-0"><a onClick={onConfirmDenial} className="btn next-btn">Confirm Denial</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
            <TailSpin
                wrapperClass="cover-spin"
                visible={state.isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
            />
            <Modal show={state.isShowError}>
                <Modal.Body className="modal-card warning-modal-card error-modal">
                    <div>
                        <Row className="warning-modal-header">
                            <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                            <Col ><span className="cancel-modal-title">Server Error</span></Col>
                            <Col md="auto" className="close-modal-icon"><span onClick={() => setState({ ...state, isShowError: false })}>x</span></Col>
                        </Row>
                        <Row>
                            <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                        </Row>
                        <Row className="warning-modal-btn-row">
                            <Col className="p-0"><a onClick={() => setState({ ...state, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                        </Row>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};
export default UpdateRequests;
