import UserAccountModel from "../../../domain/entities/usersettings/UserProfileModel"
import { SET_User_Profile } from "../actions/actionTypes"

const initialState: UserAccountModel = {
    email: '',
    alternativeEmail: '',
    alternativeFirstName: '',
    alternativeLastName: '',
    alternativePhoneNumber: '',
    dppaOptionId: 0,
    glbaOptionId: 0,
    employerAddress: '',
    employerCity: '',
    employerName: '',
    employerState: '',
    employerZIPCode: '',
    firstName: '',
    lastName: '',
    managerName: '',
    phoneNumber: '',
    secondaryEmail: '',
    sendSearchRequestsCompletionEmail: false,
    lastTosAcceptedDate: null
}


const reducer = (state: UserAccountModel = initialState, action: ActionType) => {
    if (action.type == SET_User_Profile)
        return action.payload;
    else
        return state;
}

export default reducer