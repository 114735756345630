import UserSettingRepository from "../../../domain/repositories/usersettings/IUserSettingRepository";
import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import PaginationRequestModel from "../../../domain/entities/generic/PaginationRequestModel";
import ViewPasswordModel from "../../../domain/entities/usersettings/ViewPasswordModel";

export default class SocialConnectionListViewModel implements IBaseViewModel {
  //#region props
  public pageNumber: number;
  public pageSize: number;
  public totalCount: number;
  public searchText: string;
  public selectedItemId: string;
  public userPassword: string;
  public currentConnectionPassword: string;
  public showConfirmationModal: boolean;
  public showPasswordModal: boolean;
  public showAddModal: boolean;
  public pageData: [];
  //#endregion

  public isLoading: boolean;
  public isShowError: boolean;
  public errorMessages: string[];
  public isSuccess: boolean;

  private topic?: string;
  private subject?: Subject<unknown>;
  private userSettingRepository: UserSettingRepository;

  public constructor(userSettingRepository: UserSettingRepository) {
    this.pageNumber = 0;
    this.pageSize = 10;
    this.totalCount = 0;
    this.pageData = [];
    this.searchText = "";
    this.selectedItemId = "";
    this.userPassword = "";
    this.currentConnectionPassword = "";
    this.showPasswordModal = false;
    this.showConfirmationModal = false;
    this.showAddModal = false;

    this.isLoading = false;
    this.isShowError = false;
    this.errorMessages = [];
    this.isSuccess = false;

    this.userSettingRepository = userSettingRepository;
  }

  public onUserPasswordQueryChanged = (e: React.FormEvent) => {
    const input = e as React.FormEvent<HTMLInputElement>;
    this.userPassword = input.currentTarget.value;
    this.notifyViewAboutChanges();
  };

  public getPage = async (pageNumber: number): Promise<void> => {
    try {
      this.currentConnectionPassword = "";
      this.pageNumber = pageNumber;
      const model = {} as PaginationRequestModel;
      model.PageNumber = pageNumber;
      model.PageSize = this.pageSize;
      model.SearchText = this.searchText;
      this.isLoading = true;
      this.notifyViewAboutChanges();
      const result = await this.userSettingRepository.listSocialConnection(
        model
      );
      if (result.isSuccess) {
        this.pageData = result.value.pageData;
        this.totalCount = result.value.totalCount;
      }
      this.isLoading = false;
      this.notifyViewAboutChanges();
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
      this.notifyViewAboutChanges();
    }
  };

  public onClickDelete = async (): Promise<void> => {
    try {
      this.isLoading = true;
      this.notifyViewAboutChanges();
      const result = await this.userSettingRepository.removeSocialConnection(
        this.selectedItemId
      );
      this.isLoading = false;
      this.isShowError = !result.isSuccess;
      if (!result.isSuccess) this.errorMessages.push(result.error);
      this.isLoading = false;
      this.isSuccess = result.isSuccess;
      this.showConfirmationModal = false;
      this.getPage(this.pageNumber);
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
      this.notifyViewAboutChanges();
    }
  };

  public onClickViewPassword = async (): Promise<void> => {
    try {
      const model = {} as ViewPasswordModel;
      model.ConnectionId = this.selectedItemId;
      model.Password = this.userPassword;

      this.isLoading = true;
      this.notifyViewAboutChanges();
      const result = await this.userSettingRepository.viewPassword(model);
      this.isLoading = false;
      this.isShowError = !result.isSuccess;
      this.isSuccess = result.isSuccess;
      this.userPassword = "";
      if (!result.isSuccess) {
        this.errorMessages.push(result.error);
      } else if (result.isSuccess && result.value) {
        this.currentConnectionPassword = result.value.password;
      }
      this.isLoading = false;
      this.showPasswordModal = false;
      this.notifyViewAboutChanges();
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
      this.notifyViewAboutChanges();
    }
  };

  public openAddModal = (id: string) => {
    this.showAddModal = true;
    this.selectedItemId = id;
    this.notifyViewAboutChanges();
  };

  public closeAddModal = () => {
    this.showAddModal = false;
    this.notifyViewAboutChanges();
  };

  public closePasswordModal = () => {
    this.showPasswordModal = false;
    this.notifyViewAboutChanges();
  };

  public openPasswordModal = (id: string) => {
    this.showPasswordModal = true;
    this.currentConnectionPassword = "";
    this.selectedItemId = id;
    this.notifyViewAboutChanges();
  };

  public verificationStatusBadge = (verificationStatusEnum: number): string => {
    if (verificationStatusEnum === 0) return "warning";
    if (verificationStatusEnum === 1) return "success";
    else return "danger";
  };

  public verificationStatusText = (verificationStatusEnum: number): string => {
    if (verificationStatusEnum === 0) return "Pending";
    if (verificationStatusEnum === 1) return "Verified";
    else return "Failed";
  };

  public closeDeleteModal = () => {
    this.showConfirmationModal = false;
    this.notifyViewAboutChanges();
  };

  public openDeleteModal = (id: string) => {
    this.showConfirmationModal = true;
    this.selectedItemId = id;
    this.notifyViewAboutChanges();
  };

  public notifyViewAboutChanges = (): void => {
    const data = {
      pageSize: this.pageSize,
      pageNumber: this.pageNumber,
      totalCount: this.totalCount,
      pageData: this.pageData,
      showConfirmationModal: this.showConfirmationModal,
      showPasswordModal: this.showPasswordModal,
      userPassword: this.userPassword,
      selectedItemId: this.selectedItemId,
      currentConnectionPassword: this.currentConnectionPassword,
      showAddModal: this.showAddModal,
      isLoading: this.isLoading,
      isSuccess: this.isSuccess,
      isShowError: this.isShowError,
      errorMessages: this.errorMessages,
    };
    this.subject?.next({ topic: this.topic, data });
  };

  public attachSubject = (subject: Subject<any>, topicName: string): void => {
    this.topic = topicName;
    this.subject = subject;
  };
}
