import FormValidator from "../../utils/FormValidator";
import UserSettingRepository from "../../../domain/repositories/usersettings/IUserSettingRepository";
import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import UserAccountModel from "../../../domain/entities/usersettings/UserProfileModel";
import React from "react";

export default class UserProfileViewModel implements IBaseViewModel {
  //#region props
    public firstName?: string;
    public lastName?: string;
    public phoneNumber?: string;
    public email?: string;

    public alternativeFirstName?: string;
    public alternativeLastName?: string;
    public alternativePhoneNumber?: string;
    public alternativeEmail?: string;
    public secondaryEmail?: string;

    public managerName?: string;
    public employerName?: string;
    public employerAddress?: string;
    public employerCity?: string;
    public employerState?: string;
    public employerZIPCode?: string;

    public lastTosAcceptedDate: Date | null;

  //#endregion

  public validation: any;
  public isLoading: boolean;
  public isShowError: boolean;
  public errorMessages: string[];
  public isSaveSuccess: boolean;
  public isValidForm: boolean;

  private topic?: string;
  private subject?: Subject<any>;
  private userSettingRepository: UserSettingRepository;
  private premodifiedSettings: UserAccountModel;

  public constructor(userSettingRepository: UserSettingRepository) {
      this.firstName = '';
      this.lastName = '';
      this.phoneNumber = '';
      this.email = '';

      this.alternativeFirstName = '';
      this.alternativeLastName = '';
      this.alternativePhoneNumber = '';
      this.alternativeEmail = '';
      this.secondaryEmail = '';

      this.managerName = '';
      this.employerName = '';
      this.employerAddress = '';
      this.employerCity = '';
      this.employerState = '';
      this.employerZIPCode = '';

      this.lastTosAcceptedDate = null;

      (this.validation = {});
    this.isLoading = false;
    this.isShowError = false;
    this.errorMessages = [];
    this.isSaveSuccess = false;
    this.isValidForm = false;

    this.userSettingRepository = userSettingRepository;
    this.premodifiedSettings = {} as UserAccountModel;
    // this.loadUserProfile();
  }

  public onQueryChanged = (e: React.FormEvent): void => {
    const input = e as React.FormEvent<HTMLInputElement>;
      (this as any)[e.currentTarget.id] = input.currentTarget.value;
      this.notifyViewAboutChanges();
  };

    public setData = (data: UserAccountModel): void => {

        this.firstName = data?.firstName;
        this.lastName = data?.lastName;
        this.phoneNumber = data?.phoneNumber;
        this.email = data?.email;

        this.alternativeFirstName = data?.alternativeFirstName;
        this.alternativeLastName = data?.alternativeLastName;
        this.alternativePhoneNumber = data?.alternativePhoneNumber;
        this.alternativeEmail = data?.alternativeEmail;
        this.secondaryEmail = data?.secondaryEmail;

        this.managerName = data?.managerName;
        this.employerName = data?.employerName;
        this.employerAddress = data?.employerAddress;
        this.employerCity = data?.employerCity;
        this.employerState = data?.employerState;
        this.employerZIPCode = data?.employerZIPCode;
        this.premodifiedSettings = data;
        this.lastTosAcceptedDate = data?.lastTosAcceptedDate;
        this.notifyViewAboutChanges();
    };

    public validateForm = () => {
        if (Object.keys(this.validation).length == 0)
            this.isValidForm = true;
        else
            this.isValidForm = false;
    }

    public onClickUpdate = async (): Promise<void> => {

        try {
            const model = {} as UserAccountModel;

            if(this.firstName != this.premodifiedSettings.firstName)
                model.firstName = this.firstName;
            if(this.lastName != this.premodifiedSettings.lastName)
                model.lastName = this.lastName;
            if(this.phoneNumber != this.premodifiedSettings.phoneNumber)
                model.phoneNumber = this.phoneNumber;

            if(this.alternativeFirstName != this.premodifiedSettings.alternativeFirstName)
                model.alternativeFirstName = this.alternativeFirstName;
            if(this.alternativeLastName != this.premodifiedSettings.alternativeLastName)
                model.alternativeLastName = this.alternativeLastName;
            if(this.alternativePhoneNumber != this.premodifiedSettings.alternativePhoneNumber)
                model.alternativePhoneNumber = this.alternativePhoneNumber;
            if(this.alternativeEmail != this.premodifiedSettings.alternativeEmail)
                model.alternativeEmail = this.alternativeEmail;
            if(this.secondaryEmail != this.premodifiedSettings.secondaryEmail)
                model.secondaryEmail = this.secondaryEmail;

            if(this.managerName != this.premodifiedSettings.managerName)
                model.managerName = this.managerName;
            if(this.employerName != this.premodifiedSettings.employerName)
                model.employerName = this.employerName;
            if(this.employerAddress != this.premodifiedSettings.employerAddress)
                model.employerAddress = this.employerAddress;
            if(this.employerCity != this.premodifiedSettings.employerCity)
                model.employerCity = this.employerCity;
            if(this.employerState != this.premodifiedSettings.employerState)
                model.employerState = this.employerState;
            if(this.employerZIPCode != this.premodifiedSettings.employerZIPCode)
                model.employerZIPCode = this.employerZIPCode;

            this.isLoading = true;
            this.notifyViewAboutChanges();

            const result = await this.userSettingRepository.updateUserAccount(model);
            this.isLoading = false;
            this.isShowError = !result.isSuccess;

            if (!result.value)
                this.errorMessages.push("Failed to connect server.");

            if (result.value && typeof result.value === "object" && result.value.errors) {
                for (const key in result.value.errors)
                    this.errorMessages.push(result.value.errors[key]);
            }
            else
                this.errorMessages.push(result.value);
      
            this.isSaveSuccess = result.isSuccess;
            this.notifyViewAboutChanges();
        }
        catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }
    };

    public onFirstNameChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);

        if (!this.firstName?.replaceAll(' ', ''))
            this.validation.firstName = "First Name cannot be empty";
        else
            delete this.validation.firstName;
 
        this.notifyViewAboutChanges();
    }

    public onLastNameChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);

        if (!this.lastName?.replaceAll(' ', ''))
            this.validation.lastName = "Last Name cannot be empty";
        else
            delete this.validation.lastName;

        this.notifyViewAboutChanges();
    }

    public onPhoneNumberChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);

        if (!this.phoneNumber?.replaceAll(' ', ''))
            this.validation.phone = "Phone cannot be empty";
        else if (this.phoneNumber && !FormValidator.isValidPhone(this.phoneNumber))
            this.validation.phone = "Phone is not valid";
        else
            delete this.validation.phone;

        this.notifyViewAboutChanges();
    }

    public onAlternativePhoneNumberChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);

        if (this.alternativePhoneNumber && !FormValidator.isValidPhone(this.alternativePhoneNumber))
            this.validation.alternativePhoneNumber = "Phone is not valid";
        else
            delete this.validation.alternativePhoneNumber;

        this.notifyViewAboutChanges();
    }


    public onAlternativeEmailChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);

        if (this.alternativeEmail && !FormValidator.isValidEmail(this.alternativeEmail))
            this.validation.alternativeEmail = "Email is not valid";
        else
            delete this.validation.alternativeEmail;

        this.notifyViewAboutChanges();
    }

    public onSecondaryEmailChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);

        if (this.secondaryEmail?.replaceAll(' ', '') && this.email?.replaceAll(' ', '') && this.secondaryEmail == this.email)
            this.validation.secondaryEmail = "Secondary Email cannot be equal to Email";

        else if (this.secondaryEmail && !FormValidator.isValidEmail(this.secondaryEmail))
            this.validation.secondaryEmail = "Email is not valid";

        else
            delete this.validation.secondaryEmail;

        this.notifyViewAboutChanges();
    }

    public onManagerNameChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);
    }

    public onEmployerNameChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);

        if (!this.employerName?.replaceAll(' ', ''))
            this.validation.employerName = "Employer Name cannot be empty";
        else
            delete this.validation.employerName;

        this.notifyViewAboutChanges();
    }

    public onEmployerAddressChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);

        if (!this.employerAddress?.replaceAll(' ', ''))
            this.validation.employerAddress = "Employer Address cannot be empty";
        else
            delete this.validation.employerAddress;

        this.notifyViewAboutChanges();
    }

    public onEmployerCityChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);

        if (!this.employerCity?.replaceAll(' ', ''))
            this.validation.employerCity = "Employer City cannot be empty";
        else
            delete this.validation.employerCity;

        this.notifyViewAboutChanges();
    }

    public onEmployerStateChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);

        if (!this.employerState?.replaceAll(' ', ''))
            this.validation.employerState = "Employer State cannot be empty";
        else
            delete this.validation.employerState;

        this.notifyViewAboutChanges();
    }

    public onEmployerZIPCodeChanged = (e: React.FormEvent) => {
        this.onQueryChanged(e);

        if (!this.employerZIPCode?.replaceAll(' ', ''))
            this.validation.employerZIPCode = "Employer ZIP Code cannot be empty";
        else
            delete this.validation.employerZIPCode;

        this.notifyViewAboutChanges();
    }

    private notifyViewAboutChanges = (): void => {

        this.validateForm();

        const data = {

            firstName: this.firstName,
            lastName: this.lastName,
            phoneNumber: this.phoneNumber,
            email: this.email,

            alternativeFirstName: this.alternativeFirstName,
            alternativeLastName: this.alternativeLastName,
            alternativePhoneNumber: this.alternativePhoneNumber,
            alternativeEmail: this.alternativeEmail,
            secondaryEmail: this.secondaryEmail,

            managerName: this.managerName,
            employerName: this.employerName,
            employerAddress: this.employerAddress,
            employerCity: this.employerCity,
            employerState: this.employerState,
            employerZIPCode: this.employerZIPCode,

            lastTosAcceptedDate: this.lastTosAcceptedDate,

            validation: this.validation,
            isLoading: this.isLoading,
            isSuccess: this.isSaveSuccess,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages,
            isValidForm: this.isValidForm
        };

        this.subject?.next({ topic: this.topic, data });
    };

  public attachSubject = (subject: Subject<any>, topicName: string): void => {
    this.topic = topicName;
    this.subject = subject;
  };
}
