import React from "react";
import { Col, Row } from "react-bootstrap";
import AddUpdateCompanyViewModel from "../../view-model/admin/AddUpdateCompanyViewModel";
import { CompanyState } from "../../views/admin/AddUpdateCompany";


type Props = {
    state: CompanyState,
    viewModel: AddUpdateCompanyViewModel
}

const BillingPointOfContact: React.FC<Props> = ({ state, viewModel }) => {


    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Billing Point of Contact</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="same-as-account-poc">
            <Col md="auto"><input onChange={viewModel.onBillingPocSameAsAccountPocChange} type="checkbox" checked={state.billingPocSameAsAccountPoc} /></Col>
            <Col className="p-0"><span>Same as Account POC</span></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Name"
                    name="billingPointOfContactName"
                    id="billingPointOfContactName"
                    onChange={viewModel.onBillingPointOfContactNameChange}
                    value={state.company.billingPointOfContact?.name}
                    className={`form-control ${state.validation.billingPointOfContactName ? "is-invalid" : ""}`}
                />
                <label className="form-label">Name<span className="mandatory-symbol">*</span></label>
                {state.validation.billingPointOfContactName && (
                    <div className="invalid-feedback">
                        {state.validation.billingPointOfContactName}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Email"
                    name="billingPointOfContactEmail"
                    id="billingPointOfContactEmail"
                    onChange={viewModel.onBillingPointOfContactEmailChange}
                    value={state.company.billingPointOfContact?.email}
                    className={`form-control ${state.validation.billingPointOfContactEmail ? "is-invalid" : ""}`}
                />
                <label className="form-label">Email<span className="mandatory-symbol">*</span></label>
                {state.validation.billingPointOfContactEmail && (
                    <div className="invalid-feedback">
                        {state.validation.billingPointOfContactEmail}
                    </div>
                )}
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input" md="6">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Phone"
                    name="billingPointOfContactPhone"
                    id="billingPointOfContactPhone"
                    onChange={viewModel.onBillingPointOfContactPhoneChange}
                    value={state.company.billingPointOfContact?.phone}
                    className={`form-control ${state.validation.billingPointOfContactPhone ? "is-invalid" : ""}`}
                />
                <label className="form-label">Phone<span className="mandatory-symbol">*</span></label>
                {state.validation.billingPointOfContactPhone && (
                    <div className="invalid-feedback">
                        {state.validation.billingPointOfContactPhone}
                    </div>
                )}
            </Col>
        </Row>
    </>
};

export default BillingPointOfContact;
