import React from 'react';
import RegisterModel from "../../../domain/entities/auth/structures/RegisterModel";
import FormValidator from "../../utils/FormValidator";
import AuthRepository from "../../../domain/repositories/auth/IAuthRepository";
import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import GLBAOption from "../../../domain/entities/usersettings/GLBAOption";
import DPPAOption from "../../../domain/entities/usersettings/DPPAOption";
import Question from "../../../domain/entities/usersettings/Question";
import RegistrationDataRepository from "../../../domain/repositories/registrationData/IRegistrationDataRepo";
import Result from "../../../domain/common/Result";
import GlbaDppaOptionsModel from "../../../domain/entities/auth/models/GlbaDppaOptionsModel";
import LocalStorageService from "../../utils/LocalStorageService";

const DppaDefaultValue = 8;
const GlbaDefaultValue = 7;
const NumberOfRequiredInputs = 11;

export default class RegisterViewModel implements IBaseViewModel {
    //#region props
  public isTermsOfServiceChecked: boolean;
  public isPrivacyPolicyChecked: boolean;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public alternativeFirstName: string | null;
  public alternativeLastName: string | null;
  public alternativeEmail: string | null;
  public alternativePhone: string | null;
  public secondaryEmail: string | null;
  public employerName: string;
  public managerName: string | null;
  public employerAddress: string;
  public city: string;
  public state: string;
  public zipCode: string;
  public acceptedToSPPVer: string;
  public DPPAOptionId: number;
  public GLBAOptionId: number;
  public validation: any;
  public touchedRequiredInputs: string;

  questionId : number;
  answer: string;
  //#endregion

  public isLoading: boolean;
  public isShowError: boolean;
  public errorMessages: string[];
  public isSuccess: boolean;
  public startTypePassword: boolean;
  public formIsValid: boolean;
  public showCancelModal: boolean;
  public showGlbaDppaWarningModal: boolean;
  public glbaDppaSelectedOptionWarning: any;

  public GLBAOptions: GLBAOption[];
  public DPPAOptions: DPPAOption[];
  public Questions: Question[];

  private topic?: string;
  private subject?: Subject<any>;

  private authRepository: AuthRepository;
  private registrationDataRepository: RegistrationDataRepository;

  public constructor(authRepository: AuthRepository, registrationDatRepository: RegistrationDataRepository) {
    this.isLoading = false;
    this.isShowError = false;
    this.errorMessages = [];
    this.validation = {};
    this.isSuccess = false;
    this.startTypePassword = false;
    this.authRepository = authRepository;
    this.registrationDataRepository = registrationDatRepository;
    this.formIsValid = false;
    this.touchedRequiredInputs = '';
    this.showCancelModal = false;
    this.showGlbaDppaWarningModal = false;
    this.glbaDppaSelectedOptionWarning = ''

    this.questionId = 0;
    this.answer = "";

    this.isTermsOfServiceChecked = false;
    this.isPrivacyPolicyChecked = false;
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.phone = "";
    this.alternativeFirstName = null;
    this.alternativeLastName = null;
    this.alternativeEmail = null;
    this.alternativePhone = null;
    this.secondaryEmail = null;
    this.acceptedToSPPVer = "";
    
    this.managerName = null;
    this.employerName = "";
    this.employerAddress = "";
    this.city = "";
    this.state = "";
    this.zipCode = "";

    this.DPPAOptionId = DppaDefaultValue;
    this.GLBAOptionId = GlbaDefaultValue;

    this.DPPAOptions = [];
    this.GLBAOptions = [];
    this.Questions = [];
    }

    public resetIsShowError = () => {
        this.isShowError = false;
        this.notifyViewAboutChanges();
    }

    public hideCancelModal = () => {
        this.showCancelModal = false;
        this.notifyViewAboutChanges();
    }

    public cancelRegistration = () => {
        window.location.href = '/login';
    }

    public onCancel = () => {
        this.showCancelModal = true;
        this.notifyViewAboutChanges();
    }

    public hideGlbaDppaWarningModal = () => {
        this.showGlbaDppaWarningModal = false;
        this.notifyViewAboutChanges();
    }

    public onQueryChanged = (e: React.FormEvent): void => {
        const input = e as React.FormEvent<HTMLInputElement>;
        (this as any)[e.currentTarget.id] = input.currentTarget.value;
        this.notifyViewAboutChanges();
    };

    public onCheckboxChanged = (e: React.FormEvent): void => {
        const input = e as React.FormEvent<HTMLInputElement>;
        (this as any)[e.currentTarget.id] = input.currentTarget.checked;
        this.notifyViewAboutChanges();
    };

    public onInputChanged = (inputId: string, value: number) => {
        (this as any)[inputId] = value;
        this.notifyViewAboutChanges();
    }

    public onClickRegister = async (): Promise<void> => {

        if (this.GLBAOptionId == GlbaDefaultValue || this.DPPAOptionId == DppaDefaultValue) {
            this.buildGlbaDppaWarningMessage();
            this.showGlbaDppaWarningModal = true;
            this.notifyViewAboutChanges();
            return;
        }
      this.register();
    };

    public buildGlbaDppaWarningMessage = () => {
        const glbaOptionText = this.GLBAOptionId == GlbaDefaultValue ? this.GLBAOptions.filter(o => o.id == GlbaDefaultValue)?.at(0)?.text : '';
        const dppaOptionText = this.DPPAOptionId == DppaDefaultValue ? this.DPPAOptions.filter(o => o.id == DppaDefaultValue)?.at(0)?.text : '';

        if (glbaOptionText && dppaOptionText)
            this.glbaDppaSelectedOptionWarning = <span>&nbsp; &quot;{glbaOptionText} &quot; &nbsp; and &nbsp; &quot;{dppaOptionText} &quot; &nbsp; options &nbsp;</span>;
        else
            this.glbaDppaSelectedOptionWarning = glbaOptionText ? <span> &nbsp; &quot;{glbaOptionText}&quot; &nbsp; option &nbsp;</span> : <span> &nbsp; &quot;{dppaOptionText} &quot; &nbsp; option &nbsp;</span>;
    }

    public register = async () => {
        try {
            this.hideGlbaDppaWarningModal();

            const model = {} as RegisterModel;

            model.firstName = this.firstName;
            model.lastName = this.lastName;
            model.email = this.email;
            model.phoneNumber = this.phone.replaceAll(/[.|(|)|\-| ]/g,"");
            model.alternativeFirstName = this.alternativeFirstName;
            model.alternativeLastName = this.alternativeLastName;
            model.alternativeEmail = this.alternativeEmail;
            model.alternativePhoneNumber = this.alternativePhone?.replaceAll(/[.|(|)|\-| ]/g, "") ?? null;
            model.secondaryEmail = this.secondaryEmail?.replaceAll(' ', '') ? this.secondaryEmail : null;
            model.managerName = this.managerName;
            model.employerName = this.employerName;
            model.employerAddress = this.employerAddress;
            model.employerCity = this.city;
            model.employerState = this.state;
            model.employerZIPCode = this.zipCode;
            model.dppaOptionId = this.DPPAOptionId;
            model.glbaOptionId = this.GLBAOptionId;
            model.securityQuestionId = this.questionId;
            model.securityQuestionAnswer = this.answer;
            model.acceptedToSPPVersion = (this.isTermsOfServiceChecked ? + LocalStorageService.getTermsOfServiceVersion() : '') + ',' + (this.isPrivacyPolicyChecked ? LocalStorageService.getPrivacyPolicyVersion() : '');

            this.errorMessages = [];
            this.isLoading = true;
            this.notifyViewAboutChanges();
            const result = await this.authRepository.register(model);
            this.isLoading = false;
            this.isShowError = !result.isSuccess;
            if (this.isShowError) {
                if (result.value && result.value.errors) {
                    for (const key in result.value.errors) {
                        this.errorMessages.push(result.value.errors[key]);
                    }

                }
                else if (result.value && result.value.detail) {
                    this.errorMessages.push(result.value.detail);
                }
                else {
                    if (!result.value) {
                        this.errorMessages.push("Failed to connect server.");
                    }
                    if (typeof result.value == "string") {
                        this.errorMessages.push(result.value);
                    } else {
                        this.errorMessages.push("Server Error.");
                    }
                }
            }
            this.isSuccess = result.isSuccess;
            this.notifyViewAboutChanges();
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }
    }

  public getData = async (): Promise<Result> => {
    try {
      this.isLoading = true;
      this.notifyViewAboutChanges();
      
      const questionsResult = await this.registrationDataRepository.Questions();
      if (questionsResult.isSuccess) 
        this.Questions = questionsResult.value;
            
      const glbaDppaResult = await this.registrationDataRepository.GetGlbaDppaData();
      if (glbaDppaResult.isSuccess){
        const value: GlbaDppaOptionsModel = glbaDppaResult.value;
        this.DPPAOptions = value.dppaOptions;
        this.GLBAOptions = value.glbaOptions;
      }
      this.isLoading = false;
      this.isShowError = !questionsResult.isSuccess;
      this.errorMessages.push(questionsResult.error);
      this.notifyViewAboutChanges();
      return questionsResult;
    } catch (e: any) {
      this.isLoading = false;
      this.errorMessages.push(e.message);
      this.isShowError = true;
      return Result.Fail(e.message, 500);
    }
    };

    public touch(inputName: string) {
        const requiredInputs = this.touchedRequiredInputs.split(',');
        const touchedInput = requiredInputs?.find(i => i == inputName);
        if (!touchedInput)
            this.touchedRequiredInputs += (inputName + ',');
    }

    public allRequiredInputsAreTouched = () => this.touchedRequiredInputs.split(',')?.length - 1 == NumberOfRequiredInputs;

    public onFirstNameChange = (e: React.FormEvent) => {
        this.touch('firstName');
        this.firstName = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.firstName.replaceAll(' ', ''))
            this.validation.firstName = "First Name cannot be empty";
        else
            delete this.validation.firstName;
        this.notifyViewAboutChanges();
    }

    public onLastNameChange = (e: React.FormEvent) => {
        this.touch('lastName');
        this.lastName = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.lastName.replaceAll(' ', ''))
            this.validation.lastName = "Last Name cannot be empty";
        else
            delete this.validation.lastName;
        this.notifyViewAboutChanges();
    } 

    public onEmailChange = (e: React.FormEvent) => {
        const input = e as React.FormEvent<HTMLInputElement>;
        (this as any)[e.currentTarget.id] = input.currentTarget.value;

        if (e.currentTarget.id == 'email')
            this.touch('email');

        if (e.currentTarget.id == 'email' && !this.email.replaceAll(' ', ''))
            this.validation.email = "Email cannot be empty";
        else if (this.email && !FormValidator.isValidEmail(this.email))
            this.validation.email = "Email is not valid";
        else if (this.validation.email)
            delete this.validation.email;

        if (this.secondaryEmail && !FormValidator.isValidEmail(this.secondaryEmail))
            this.validation.secondaryEmail = "Secondary Email is not valid";
        else if (this.secondaryEmail && this.secondaryEmail.length > 0 && this.secondaryEmail == this.email)
            this.validation.secondaryEmail = "Secondary Email Cannot be equal to Email";
        else if (this.validation.secondaryEmail)
            delete this.validation.secondaryEmail;

        this.notifyViewAboutChanges();
    }

    public onEmailBlur = () => this.authRepository.isEmailExist(this.email).then(r => {
        if (r.value) {
            this.validation.email = "Invalid Email. Please email our support team at intertel@intertelgo.com";
            this.notifyViewAboutChanges();
        }
    });

    public onPhoneChange = (e: React.FormEvent) => {
        this.touch('phone');
        this.phone = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.phone.replaceAll(' ', ''))
            this.validation.phone = "Phone Number cannot be empty";
        else if (this.phone && !FormValidator.isValidRegistrationPhone(this.phone))
            this.validation.phone = "Phone is not valid";
        else
            delete this.validation.phone;
        this.notifyViewAboutChanges();
    }

    public onEmployerNameChange = (e: React.FormEvent) => {
        this.touch('employerName');
        this.employerName = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.employerName.replaceAll(' ', ''))
            this.validation.employerName = "Employer Name cannot be empty";
        else
            delete this.validation.employerName;
        this.notifyViewAboutChanges();
    } 

    public onEmployerAddressChange = (e: React.FormEvent) => {
        this.touch('employerAddress');
        this.employerAddress = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.employerAddress.replaceAll(' ', ''))
            this.validation.employerAddress = "Employer Address cannot be empty";
        else
            delete this.validation.employerAddress;
        this.notifyViewAboutChanges();
    } 

    public onEmployerCityChange = (e: React.FormEvent) => {
        this.touch('city');
        this.city = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.city.replaceAll(' ', ''))
            this.validation.city = "Employer City cannot be empty";
        else
            delete this.validation.city;
        this.notifyViewAboutChanges();
    } 

    public onEmployerStateChange = (e: React.FormEvent) => {
        this.touch('state');
        this.state = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.state)
            this.validation.state = "Employer State cannot be empty";
        else
            delete this.validation.state;
        this.notifyViewAboutChanges();
    } 

    public onEmployerZipCodeChange = (e: React.FormEvent) => {
        this.touch('zipCode');
        this.zipCode = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.zipCode.replaceAll(' ', ''))
            this.validation.zipCode = "Employer ZIP Code cannot be empty";
        else
            delete this.validation.zipCode;
        this.notifyViewAboutChanges();
    } 

    public onSecurityQuestionChange = (e: React.FormEvent) => {
        this.touch('question');
        this.questionId = +(e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        this.answer = '';
        if (this.touchedRequiredInputs.includes('answer,'))
            this.touchedRequiredInputs = this.touchedRequiredInputs.replace('answer,', '');
        if (+this.questionId == 0) {
            this.validation.question = "Security Question cannot be empty";
            delete this.validation.answer;
        }
        else
            delete this.validation.question;
        this.notifyViewAboutChanges();
    } 

    public onSecurityQuestionAnswerChange = (e: React.FormEvent) => {
        this.touch('answer');
        this.answer = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (+this.questionId && !this.answer.replaceAll(' ', ''))
            this.validation.answer = "Answer cannot be empty";
        else
            delete this.validation.answer;
        this.notifyViewAboutChanges();
    } 

    public onAlternativeEmailChange = (e: React.FormEvent) => {
        this.alternativeEmail = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (this.alternativeEmail && this.alternativeEmail.length > 0 && this.alternativeEmail == this.email)
            this.validation.alternativeEmail = "Alternative Email Cannot be equal to Email";
        else if (this.alternativeEmail && !FormValidator.isValidEmail(this.alternativeEmail))
            this.validation.alternativeEmail = "Alternative Email is not valid";
        else
            delete this.validation.alternativeEmail;
        this.notifyViewAboutChanges();
    } 

    public onAlternativePhoneChange = (e: React.FormEvent) => {
        this.alternativePhone = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (this.alternativePhone && !FormValidator.isValidRegistrationPhone(this.alternativePhone))
            this.validation.alternativePhone = "Alternative Phone is not valid";
        else
            delete this.validation.alternativePhone;
        this.notifyViewAboutChanges();
    }

    private validateRegisterForm = () => {
        if (this.allRequiredInputsAreTouched() && Object.keys(this.validation).length == 0)
            this.formIsValid = true;
        else
            this.formIsValid = false;
    };

    private notifyViewAboutChanges = (): void => {

      this.validateRegisterForm();     

      const data = {
      isTermsOfServiceChecked: this.isTermsOfServiceChecked,
      isPrivacyPolicyChecked: this.isPrivacyPolicyChecked,
      questionId: this.questionId,
      answer: this.answer,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      phone: this.phone,
      alternativeFirstName: this.alternativeFirstName,
      alternativeLastName: this.alternativeLastName,
      alternativeEmail: this.alternativeEmail && this.alternativeEmail.length > 0 ? this.alternativeEmail : null,
      alternativePhone: this.alternativePhone,
      secondaryEmail: this.secondaryEmail,
      managerName: this.managerName,
      employerName: this.employerName,
      employerAddress: this.employerAddress,
      city: this.city,
      state: this.state,
      zipCode: this.zipCode,
      DPPAOptionId: this.DPPAOptionId,
      GLBAOptionId: this.GLBAOptionId,
      validation: this.validation,
      isLoading: this.isLoading,
      isSuccess: this.isSuccess,
      isShowError: this.isShowError,
      errorMessages: this.errorMessages,
      startTypePassword: this.startTypePassword,
      Questions: this.Questions,
      GLBAOptions: this.GLBAOptions,
      DPPAOptions: this.DPPAOptions,
      formIsValid: this.formIsValid,
      showCancelModal: this.showCancelModal,
      showGlbaDppaWarningModal: this.showGlbaDppaWarningModal,
      glbaDppaSelectedOptionWarning: this.glbaDppaSelectedOptionWarning
    };
    this.subject?.next({ topic: this.topic, data });
  };

  public attachSubject = (subject: Subject<any>, topicName: string): void => {
    this.topic = topicName;
    this.subject = subject;
    };
}
