import Result from '../../domain/common/Result';
import { HttpClient } from '../../infrastructure/utils/fetchInterceptor';
import RegistrationDataRepository from '../../domain/repositories/registrationData/IRegistrationDataRepo';
import GlbaDppaOptionsModel from '../../domain/entities/auth/models/GlbaDppaOptionsModel';

export default class RegistrationDataApi implements RegistrationDataRepository {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    Questions = (): Promise<Result<any>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/GenericData/Questions`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<any>(await res.json());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);

            return Result.Fail('Server Error', res.status);

        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    GetGlbaDppaData = (): Promise<Result<any>> => {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        return this.httpClient
            .httpFetch('/api/GenericData/DPPAGLBA', requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.OkWithValue<GlbaDppaOptionsModel>(await res.json());
                return Result.Fail("Server Error", res.status);
            })
            .catch((e) => {
                return Result.Fail(e.message, 500);
            });
    };
}
