import React from "react";
import { Col, Row } from "react-bootstrap";
import AddUpdateCompanyViewModel, { INVOICE, NONE } from "../../view-model/admin/AddUpdateCompanyViewModel";
import { CompanyState } from "../../views/admin/AddUpdateCompany";


type Props = {
    state: CompanyState,
    viewModel: AddUpdateCompanyViewModel
}

const MainInfo: React.FC<Props> = ({ state, viewModel }) => {


    return <>
        <Row className="ml-n-25px section">
            <Col md="auto" className="section-title">Main Information</Col>
            <Col><hr /></Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    id="firstName"
                    onChange={viewModel.onCompanyNameChange}
                    value={state.company.name}
                    className={`form-control ${state.validation.companyName ? "is-invalid" : ""}`}
                />
                <label className="form-label">Name<span className="mandatory-symbol">*</span></label>
                {state.validation.companyName && (
                    <div className="invalid-feedback">
                        {state.validation.companyName}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <select name="statusId" id="statusId" className="form-control" onChange={viewModel.onStatusChange} value={state.company.statusId}>
                    {state.statusList.map(s => <option key={Math.random()} value={s.id}>{s.name}</option>)}
                </select>
                <label className="form-label">Status</label>
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating">
                <input autoComplete="new-password"
                    type="text"
                    placeholder="Address"
                    name="companyAddress"
                    id="companyAddress"
                    onChange={viewModel.onCompanyAddressChange}
                    value={state.company.address}
                    className={`form-control ${state.validation.companyAddress ? "is-invalid" : ""}`}
                />
                <label className="form-label">Address<span className="mandatory-symbol">*</span></label>
                {state.validation.companyAddress && (
                    <div className="invalid-feedback">
                        {state.validation.companyAddress}
                    </div>
                )}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <input
                    type="text" autoComplete="new-password"
                    placeholder="Netsuite External ID"
                    name="netsuiteExternalId"
                    id="netsuiteExternalId"
                    onChange={viewModel.onNetsuiteExternalIdChange}
                    value={state.company.netsuiteExternalId}
                    className={`form-control ${state.validation.netsuiteExternalId ? "is-invalid" : ""}`}
                />
                <label className="form-label">Netsuite External ID<span className="mandatory-symbol">*</span></label>
                {state.validation.netsuiteExternalId && (
                    <div className="invalid-feedback">
                        {state.validation.netsuiteExternalId}
                    </div>
                )}
            </Col>
        </Row>
        <Row className="row-style">
            <Col className="input-group-meta form-group mb-30 form-floating" md="6">
                <input
                    type="number"
                    placeholder="Price/Search"
                    name="pricePerSearch"
                    id="pricePerSearch"
                    onChange={viewModel.onPricePerSearchChange}
                    value={state.company.pricePerSearch}
                    className={`form-control ${state.validation.pricePerSearch ? "is-invalid" : ""}`}
                />
                <label className="form-label">Price/Search ($)<span className="mandatory-symbol">*</span></label>
                {state.validation.pricePerSearch && (<div className="invalid-feedback">{state.validation.pricePerSearch}</div>)}
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                <select name="isInvoiceable" id="isInvoiceable" className="form-control" onChange={viewModel.onInvoiceableChange} value={state.company.isInvoiceable ? INVOICE : NONE}>
                    <option value={INVOICE}>{INVOICE}</option>
                    <option value={NONE}>{NONE}</option>
                </select>
                <label className="form-label">Payment Method</label>
            </Col>
        </Row>
        <Row className="row-style">
            <Col md="6">
                {state.domainFormValues.map((domainItem, index) => (
                    <div className="input-group-meta form-group" key={index}>
                        <div className="remove-extra-input-div form-floating">
                            <input autoComplete="new-password" type="text" name="domain" id="domain" placeholder="Domain"
                                onChange={(e: React.FormEvent<HTMLInputElement>): void => { viewModel.onDomainChanged(e.currentTarget.value, index) }}
                                value={domainItem || ""}
                                className={`form-control ${state.validation.domains && state.validation.domains[index] ? "is-invalid" : ""}`}
                            />
                            <label className="form-label domain-label">Domain<span className="mandatory-symbol">*</span></label>
                            {state.validation.domains && state.validation.domains[index] && (
                                <div className="side-invalid-feedback mb-2">
                                    {state.validation.domains[index]}
                                </div>
                            )}
                            {index || state.domainFormValues.length > 1 && index > 0 ? (<span className="search-input-icon bi bi-trash" onClick={() => viewModel.onDomainRemoved(index)}></span>) : null}
                        </div>
                    </div>
                ))}
                <div className="extra-input-add-div">
                    <p className="extra-input-add-text d-flex" onClick={() => viewModel.onDomainAdded()}>
                        <img className="extra-input-add-icon" src="/images/add-input.svg" alt="" />
                        Add Domain
                    </p>
                </div>
            </Col>
            <Col className="input-group-meta form-group mb-30 form-floating first-col-input">
                    <input autoComplete="new-password"
                        type="text"
                        placeholder="Employer Identification Number"
                        name="employerIdentificationNumber"
                        id="employerIdentificationNumber"
                        onChange={viewModel.onEmployerIdentificationNumberChange}
                        value={state.company.employerIdentificationNumber}
                        className={`form-control ${state.validation.employerIdentificationNumber ? "is-invalid" : ""}`}
                    />
                    <label className="form-label">Employer Identification Number</label>
                    {state.validation.employerIdentificationNumber && (
                        <div className="invalid-feedback">
                            {state.validation.employerIdentificationNumber}
                        </div>
                    )}
                <Row>
                    <Col className="input-group-meta form-group mb-30 form-floating first-col-input signed-contract-div">
                        <Col md="auto" className="p-0"><input className="p-0" type="checkbox" id="signedContractId" onChange={viewModel.onSignedChange} checked={state.company.signedContract} /></Col>
                        <Col><label htmlFor="signedContractId" className="form-label signed-contract-toggle-label">Signed Contract</label></Col>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>
};

export default MainInfo;
