import type UserSettingRepository from "../../domain/repositories/usersettings/IUserSettingRepository";
import Result from "../../domain/common/Result";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import UserAccountModel from "../../domain/entities/usersettings/UserProfileModel";
import SocialConnectionModel from "../../domain/entities/usersettings/SocialConnectionModel";
import PaginationRequestModel from "../../domain/entities/generic/PaginationRequestModel";
import ViewPasswordModel from "../../domain/entities/usersettings/ViewPasswordModel";
import UserSearchRequestsViewPermissionRequestModel from "../../domain/entities/usersettings/UserSearchRequestsViewPermissionRequestModel";
import GlbaDppaChangeRequestModel from "../../domain/entities/usersettings/GlbaDppaChangeRequestModel";


export default class UserSettingApi implements UserSettingRepository {
  httpClient: HttpClient;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  addSocialConnection(object: SocialConnectionModel): Promise<Result<string>> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    return this.httpClient
      .httpFetch(`/api/UserSocialConnection/Add`, requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<any>(await res.json());
        return Result.FailWithValue<any>("Validation Error", await res.json(), res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

  updateSocialConnection(
    object: SocialConnectionModel
  ): Promise<Result<string>> {
    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    return this.httpClient
      .httpFetch(
        `/api/UserSocialConnection/Update`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<any>(await res.json());
        return Result.FailWithValue<any>("Validation Error", await res.json(), res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

  getSocialConnectionDetails(id: string): Promise<Result<any>> {
    const requestOptions = {
      method: "GET",
    };

    return this.httpClient
      .httpFetch(
        `/api/UserSocialConnection/Details?id=${id}`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<any>(await res.json());
        return Result.FailWithValue<any>("Validation Error", await res.json(), res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

  removeSocialConnection(id: string): Promise<Result<string>> {
    const requestOptions = {
      method: "DELETE",
    };

    return this.httpClient
      .httpFetch(
        `/api/UserSocialConnection/Delete?id=${id}`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<any>(await res.json());
        return Result.FailWithValue<any>("Validation Error", await res.json(), res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

  listSocialConnection(object: PaginationRequestModel): Promise<Result<any>> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };
    return this.httpClient
      .httpFetch(
        `/api/UserSocialConnection/List`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200) return Result.OkWithValue(await res.json());
        return Result.Fail("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

  listSocialTypes(): Promise<Result<any>> {
    const requestOptions = {
      method: "GET",
    };
    return this.httpClient
      .httpFetch(
        `/api/SocialConnectionType/List`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200) return Result.OkWithValue(await res.json());
        return Result.Fail("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

  viewPassword(object: ViewPasswordModel): Promise<Result<any>> {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };
    return this.httpClient
      .httpFetch(
        `/api/UserSocialConnection/ViewPassword`,
        requestOptions
      )
      .then(async (res) => {
        if (res.status === 200) return Result.OkWithValue(await res.json());
        if (res.status === 403) return Result.Fail("Wrong password", 200);
        return Result.Fail("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

    getUserAccount(): Promise<Result<any>> {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify(object)
    };

    return this.httpClient
      .httpFetch(`/api/User/GetUserAccount`, requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<UserAccountModel>(await res.json());
        return Result.Fail("Server Error", res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
  }

    updateUserAccount(object: UserAccountModel): Promise<Result<any>> {
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(object),
    };

    return this.httpClient
      .httpFetch(`/api/User/UpdateUserAccount`, requestOptions)
      .then(async (res) => {
        if (res.status === 200)
          return Result.OkWithValue<any>(await res.json());
        return Result.FailWithValue<any>("Validation Error", await res.json(), res.status);
      })
      .catch((e) => {
        return Result.Fail(e.message, 500);
      });
    }

    GetSearchRequestsViewPermissionRequest = (): Promise<Result<any>> => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        return this.httpClient.httpFetch(`/api/UserRequests/GetSearchRequestsViewPermissionRequest`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<UserSearchRequestsViewPermissionRequestModel[]>(await res.json());
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    AddSearchRequestsViewPermissionRequest = (requestedUsersEmails: string): Promise<Result<string>> => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(requestedUsersEmails)
        };
        return this.httpClient.httpFetch(`/api/UserRequests/AddSearchRequestsViewPermissionRequest`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            else if (res.status == 401)
                return Result.Fail('Unauthorized', 401);
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    addGlbaDppaChangeRequest(object: GlbaDppaChangeRequestModel): Promise<Result<any>> {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(object),
        };

        return this.httpClient
            .httpFetch(`/api/UserRequests/AddGlbaDppaChangeRequest`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.Ok();
               else if (res.status == 401)
                    return Result.Fail('Unauthorized', 401);
               return Result.Fail('Server Error', res.status);
            })
            .catch((e) => {
                return Result.Fail(e.message, 500);
            });
    }

    getGlbaDppaChangeRequest(): Promise<Result<any>> {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        return this.httpClient
            .httpFetch(`/api/UserRequests/GetGlbaDppaChangeRequest`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.OkWithValue<GlbaDppaChangeRequestModel>(await res.json());
                else if (res.status == 401)
                    return Result.Fail('Unauthorized', 401);
                return Result.Fail('Server Error', res.status);
            })
            .catch((e) => {
                return Result.Fail(e.message, 500);
            });
    }

    getAccountGlbaDppa(): Promise<Result<any>> {
        const requestOptions = {
            method: "GET",
            headers: { "Content-Type": "application/json" }
        };

        return this.httpClient
            .httpFetch(`/api/User/GetAccountGlbaDppa`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.OkWithValue<GlbaDppaChangeRequestModel>(await res.json());
                else if (res.status == 401)
                    return Result.Fail('Unauthorized', 401);
                return Result.Fail('Server Error', res.status);
            })
            .catch((e) => {
                return Result.Fail(e.message, 500);
            });
    }

    updateAccountCommunication(sendSearchRequestsCompletionEmail: boolean): Promise<Result<string>> {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(sendSearchRequestsCompletionEmail)
        };

        return this.httpClient
            .httpFetch(`/api/User/UpdateAccountCommunication`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.Ok();
                else if (res.status == 401)
                    return Result.Fail('Unauthorized', 401);
                return Result.Fail('Server Error', res.status);
            })
            .catch((e) => {
                return Result.Fail(e.message, 500);
            });
    }

    acceptTermsAndConditionAndPrivacyPolicyLastVersion(): Promise<Result<string>>{
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" }
        };

        return this.httpClient
            .httpFetch(`/api/User/AcceptLastTermsAndConditionsAndPrivacyPolicyVersion`, requestOptions)
            .then(async (res) => {
                if (res.status === 200)
                    return Result.Ok();
                else if (res.status == 401)
                    return Result.Fail('Unauthorized', 401);
                return Result.Fail('Server Error', res.status);
            })
            .catch((e) => {
                return Result.Fail(e.message, 500);
            });
    }
}
