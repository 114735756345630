export default class Result<T = string> {
    public isSuccess: boolean;
    public error: string;
    public statusCode: number;
    private _value?: T;

    private constructor(isSuccess: boolean, error: string, errorCode: number, value?: T) {
        if (isSuccess && error)
            throw Error('Invalid Operation.')
        if (!isSuccess && !error)
            throw Error('Invalid Operation.')

        this.statusCode = errorCode;
        this.isSuccess = isSuccess;
        this.error = error;
        if (value) {
            this._value = value;
        }
    }

    public get value(): T | null {
        if (this._value) {
            return this._value
        }
        return null;
    }

    public static Fail(message: string, errorCode: number | null = null): Result {
        if(errorCode){
            return new Result(false, message, errorCode)
        }
        return new Result(false, message, 500)
    }

    public static FailWithValue<T>(message: string, value: T, errorCode: number): Result<T> {
        return new Result<T>(false, message, errorCode, value);
    }

    public static Ok(): Result {
        return new Result(true, "", 200);
    }

    public static OkWithValue<T>(value: T): Result<T> {
        return new Result<T>(true, "", 200, value);
    }
}