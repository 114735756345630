import React from 'react';
import FormValidator from "../../utils/FormValidator";
import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import GLBAOption from "../../../domain/entities/usersettings/GLBAOption";
import DPPAOption from "../../../domain/entities/usersettings/DPPAOption";
import Question from "../../../domain/entities/usersettings/Question";
import RegistrationDataRepository from "../../../domain/repositories/registrationData/IRegistrationDataRepo";
import Result from "../../../domain/common/Result";
import GlbaDppaOptionsModel from "../../../domain/entities/auth/models/GlbaDppaOptionsModel";
import IUserAccountRepo from '../../../domain/repositories/admin/IUserAccountRepo';
import { toast } from 'react-toastify';
import UserInfoModel from '../../../domain/entities/admin/models/UserInfoModel';
import UserEventModel from '../../../domain/entities/admin/models/UserEventModel';
import ICompanyRepo from '../../../domain/repositories/admin/ICompanyRepo';
import CompanyNameModel from '../../../domain/entities/admin/models/CompanyNameModel';

export default class UserInfoViewModel implements IBaseViewModel {
    //#region props
    public userId: string;
    public firstName: string;
    public lastName: string;
    public email: string;
    public phone: string;
    public alternativeFirstName: string | null;
    public alternativeLastName: string | null;
    public alternativeEmail: string | null;
    public alternativePhone: string | null;
    public secondaryEmail: string | null;
    public employerName: string;
    public managerName: string | null;
    public employerAddress: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public DPPAOptionId: number;
    public GLBAOptionId: number;
    public accountStatus: string;
    public companyId: number | undefined;
    public validation: any;
    public userEvents: UserEventModel[];

    questionId: number;
    answer: string;
    //#endregion

    public isLoading: boolean;
    public isShowError: boolean;
    public errorMessages: string[];
    public isSuccess: boolean;
    public startTypePassword: boolean;
    public formIsValid: boolean;
    public glbaDppaSelectedOptionWarning: any;

    public GLBAOptions: GLBAOption[];
    public DPPAOptions: DPPAOption[];
    public Questions: Question[];
    public Companies: CompanyNameModel[];

    private topic?: string;
    private subject?: Subject<any>;

    private userAccountRepo: IUserAccountRepo;
    private registrationDataRepository: RegistrationDataRepository;
    private companyRepo: ICompanyRepo;
    private showChangeAccountStatus: boolean;
    private deactivateReason: string | undefined;
    public deactivateReasonInput: string | undefined;
    public enableSavingStatusChange: boolean;
    private premodifiedSettings : UserInfoModel;
    public lastTosAcceptedDate: Date | null;

    public constructor(userAccountRepo: IUserAccountRepo, registrationDatRepository: RegistrationDataRepository, companyRepo : ICompanyRepo) {
        this.isLoading = false;
        this.isShowError = false;
        this.errorMessages = [];
        this.validation = {};
        this.isSuccess = false;
        this.startTypePassword = false;
        this.userAccountRepo = userAccountRepo;
        this.registrationDataRepository = registrationDatRepository;
        this.companyRepo = companyRepo;
        this.formIsValid = false;

        this.questionId = 0;
        this.answer = "";

        this.userId = "";
        this.firstName = "";
        this.lastName = "";
        this.email = "";
        this.phone = "";
        this.alternativeFirstName = null;
        this.alternativeLastName = null;
        this.alternativeEmail = null;
        this.alternativePhone = null;
        this.secondaryEmail = null;

        this.managerName = null;
        this.employerName = "";
        this.employerAddress = "";
        this.city = "";
        this.state = "";
        this.zipCode = "";

        this.DPPAOptionId = 0;
        this.GLBAOptionId = 0;
        this.companyId = undefined;

        this.DPPAOptions = [];
        this.GLBAOptions = [];
        this.Questions = [];
        this.Companies = [];

        this.showChangeAccountStatus = false;
        this.deactivateReason = '';
        this.accountStatus = '';
        this.deactivateReasonInput = '';
        this.enableSavingStatusChange = false;
        this.userEvents = [];
        this.premodifiedSettings = {} as UserInfoModel;
        this.lastTosAcceptedDate = null;
    }

    public openChangeAccountStatusModal = () => {
        this.showChangeAccountStatus = true;
        this.enableSavingStatusChange = false;
        this.notifyViewAboutChanges();
    }

    public closeChangeAccountStatusModal = () => {
        this.showChangeAccountStatus = false;
        this.deactivateReasonInput = '';
        this.notifyViewAboutChanges();
    }

    public onDeactivateReasonChange = (e: React.FormEvent) => {
        this.deactivateReasonInput = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.deactivateReasonInput.replaceAll(' ', ''))
            this.enableSavingStatusChange = false;
        else
            this.enableSavingStatusChange = true;
        this.notifyViewAboutChanges();
    }

    public deactivateAccount = (adminFName: string, adminLName: string) => {
        this.showChangeAccountStatus = false;
        this.isLoading = true;
        this.notifyViewAboutChanges();

        this.userAccountRepo.DeactivateUserAccount(this.userId, this.deactivateReasonInput || '').then(r => {
            this.isLoading = false;

            if (r.isSuccess) {
                this.accountStatus = 'Inactive';
                this.deactivateReason = this.deactivateReasonInput;
                this.notify();
            }

            this.isShowError = !r.isSuccess;
            this.userEvents.push({typeId: 4, typeName:"Deactivation", date: new Date(), userId: this.userId, id: 0, details: JSON.stringify({Reason:this.deactivateReasonInput}),adminFirstName: adminFName, adminLastName:adminLName})
            this.notifyViewAboutChanges();

        }).catch(_ => {
            this.isLoading = false;
            this.isShowError = true;
            this.notifyViewAboutChanges();
        });
    }

    public activateAccount = (adminFName: string, adminLName: string) => {
        this.showChangeAccountStatus = false;
        this.isLoading = true;
        this.notifyViewAboutChanges();

        this.userAccountRepo.ActivateUserAccount(this.userId).then(r => {
            this.isLoading = false;

            if (r.isSuccess) {
                this.accountStatus = 'Active';
                this.deactivateReason = '';
                this.deactivateReasonInput = '';
                this.userEvents.push({typeId: 3, typeName:"Activation", date: new Date(), userId: this.userId, id: 0, details: undefined,adminFirstName: adminFName, adminLastName:adminLName})
                this.notify();
            }

            this.isShowError = !r.isSuccess;
            this.notifyViewAboutChanges();

        }).catch(_ => {
            this.isLoading = false;
            this.isShowError = true;
            this.notifyViewAboutChanges();
        });
    }

    public notify = () => {
        toast.success("Updated successfully.", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            className: "avoid-nav-bar",
            bodyClassName: "toast-message",
        });
    };

    public onQueryChanged = (e: React.FormEvent): void => {
        const input = e as React.FormEvent<HTMLInputElement>;
        (this as any)[e.currentTarget.id] = input.currentTarget.value;
        this.notifyViewAboutChanges();
    };

    public onInputChanged = (inputId: string, value: number) => {
        (this as any)[inputId] = value;
        this.notifyViewAboutChanges();
    }

    public save = async () => {
        try {
            const model = {} as UserInfoModel;        
            model.userId = this.userId
            if(this.firstName != this.premodifiedSettings.firstName)
                model.firstName = this.firstName;
            if(this.lastName != this.premodifiedSettings.lastName)
                model.lastName = this.lastName;
            if(this.phone != this.premodifiedSettings.phoneNumber)
                model.phoneNumber = this.phone?.replaceAll(/[.|(|)|\-| ]/g, "");
            if(this.alternativeFirstName != this.premodifiedSettings.alternativeFirstName)
                model.alternativeFirstName = this.alternativeFirstName;
            if(this.alternativeLastName != this.premodifiedSettings.alternativeLastName)
                model.alternativeLastName = this.alternativeLastName;
            if(this.alternativeEmail != this.premodifiedSettings.alternativeEmail)
                model.alternativeEmail = this.alternativeEmail;
            if(this.alternativePhone != this.premodifiedSettings.alternativePhoneNumber)
                model.alternativePhoneNumber = this.alternativePhone?.replaceAll(/[.|(|)|\-| ]/g, "") ?? null;
            if(this.secondaryEmail != this.premodifiedSettings.secondaryEmail)
                model.secondaryEmail = this.secondaryEmail;
            if(this.managerName != this.premodifiedSettings.managerName)
                model.managerName = this.managerName;
            if(this.employerName != this.premodifiedSettings.employerName)
                model.employerName = this.employerName;
            if(this.employerAddress != this.premodifiedSettings.employerAddress)
                model.employerAddress = this.employerAddress;
            if(this.city != this.premodifiedSettings.employerCity)
                model.employerCity = this.city;
            if(this.state != this.premodifiedSettings.employerState)
                model.employerState = this.state;
            if(this.zipCode != this.premodifiedSettings.employerZIPCode)
                model.employerZIPCode = this.zipCode;
            if(this.DPPAOptionId != this.premodifiedSettings.dppaOptionId)
                model.dppaOptionId = this.DPPAOptionId;
            if(this.GLBAOptionId != this.premodifiedSettings.glbaOptionId)
                model.glbaOptionId = this.GLBAOptionId;
            if(this.companyId != this.premodifiedSettings.companyId)
                model.companyId = this.companyId;
            if(this.questionId != this.premodifiedSettings.securityQuestionId)
                model.securityQuestionId = this.questionId;
            if(this.answer != this.premodifiedSettings.securityQuestionAnswer)
                model.securityQuestionAnswer = this.answer;
            
            this.errorMessages = [];
            this.isLoading = true;
            this.notifyViewAboutChanges();

            const result = await this.userAccountRepo.UpdateUserInfo(model);
            this.isLoading = false;
            this.isShowError = !result.isSuccess;

            this.isSuccess = result.isSuccess;

            if (this.isSuccess){
                this.notify();
                this.premodifiedSettings = model;
            }

            this.notifyViewAboutChanges();

        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }
    }

    public getData = async (userId: string): Promise<Result> => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();

            const userData = await this.userAccountRepo.GetUserInfo(userId);
            if (userData.isSuccess) {
                const user = userData.value as UserInfoModel;
                this.premodifiedSettings = user;
                this.userId = user.userId;
                this.questionId = user.securityQuestionId ?? 0;
                this.answer = user.securityQuestionAnswer ?? "";
                this.firstName = user.firstName ?? "";
                this.lastName = user.lastName ?? "";
                this.email = user.email;
                this.phone = user.phoneNumber ?? "";
                this.alternativeFirstName = user.alternativeFirstName;
                this.alternativeLastName = user.alternativeLastName;
                this.alternativeEmail = user.alternativeEmail;
                this.alternativePhone = user.alternativePhoneNumber;
                this.secondaryEmail = user.secondaryEmail;
                this.managerName = user.managerName;
                this.employerName = user.employerName ?? "";
                this.employerAddress = user.employerAddress ?? "";
                this.city = user.employerCity ?? "";
                this.state = user.employerState ?? "";
                this.zipCode = user.employerZIPCode ?? "";
                this.DPPAOptionId = user.dppaOptionId ?? 0;
                this.GLBAOptionId = user.glbaOptionId ?? 0;
                this.accountStatus = user.accountStatus || '';
                this.companyId = user.companyId;
                this.userEvents = user.userEvents || [];
                this.formIsValid = false;
                this.lastTosAcceptedDate = user.lastTosAcceptedDate;
            }

            const questionsResult = await this.registrationDataRepository.Questions();
            if (questionsResult.isSuccess)
                this.Questions = questionsResult.value;

            const glbaDppaResult = await this.registrationDataRepository.GetGlbaDppaData();
            if (glbaDppaResult.isSuccess) {
                const value: GlbaDppaOptionsModel = glbaDppaResult.value;
                this.DPPAOptions = value.dppaOptions;
                this.GLBAOptions = value.glbaOptions;
            }

            const companiesResult = await this.companyRepo.getCompaniesNames();
            if (companiesResult.isSuccess)
                this.Companies = companiesResult.value as CompanyNameModel[];

            this.isLoading = false;
            this.isShowError = !questionsResult.isSuccess || !userData.isSuccess || !glbaDppaResult.isSuccess;
            this.errorMessages.push(questionsResult.error);
            this.notifyViewAboutChanges();
            return questionsResult;

        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
            return Result.Fail(e.message, 500);
        }
    };

    public resetError = () => {
        this.isShowError = false;
        this.notifyViewAboutChanges();
    }

    public onFirstNameChange = (e: React.FormEvent) => {
        this.firstName = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.firstName.replaceAll(' ', ''))
            this.validation.firstName = "First Name cannot be empty";
        else
            delete this.validation.firstName;
        this.notifyViewAboutChanges();
    }

    public onLastNameChange = (e: React.FormEvent) => {
        this.lastName = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.lastName.replaceAll(' ', ''))
            this.validation.lastName = "Last Name cannot be empty";
        else
            delete this.validation.lastName;
        this.notifyViewAboutChanges();
    }

    public onPhoneChange = (e: React.FormEvent) => {
        this.phone = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.phone.replaceAll(' ', ''))
            this.validation.phone = "Phone Number cannot be empty";
        else if (this.phone && !FormValidator.isValidRegistrationPhone(this.phone))
            this.validation.phone = "Phone is not valid";
        else
            delete this.validation.phone;
        this.notifyViewAboutChanges();
    }

    public onEmailChange = (e: React.FormEvent) => {
        this.secondaryEmail = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (this.secondaryEmail && !FormValidator.isValidEmail(this.secondaryEmail))
            this.validation.secondaryEmail = "Secondary Email is not valid";
        else if (this.secondaryEmail && this.secondaryEmail.length > 0 && this.secondaryEmail == this.email)
            this.validation.secondaryEmail = "Secondary Email Cannot be equal to Email";
        else
            delete this.validation.secondaryEmail;
        this.notifyViewAboutChanges();
    }

    public onEmployerNameChange = (e: React.FormEvent) => {
        this.employerName = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.employerName.replaceAll(' ', ''))
            this.validation.employerName = "Employer Name cannot be empty";
        else
            delete this.validation.employerName;
        this.notifyViewAboutChanges();
    }

    public onEmployerAddressChange = (e: React.FormEvent) => {
        this.employerAddress = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.employerAddress.replaceAll(' ', ''))
            this.validation.employerAddress = "Employer Address cannot be empty";
        else
            delete this.validation.employerAddress;
        this.notifyViewAboutChanges();
    }

    public onEmployerCityChange = (e: React.FormEvent) => {
        this.city = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.city.replaceAll(' ', ''))
            this.validation.city = "Employer City cannot be empty";
        else
            delete this.validation.city;
        this.notifyViewAboutChanges();
    }

    public onEmployerStateChange = (e: React.FormEvent) => {
        this.state = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.state)
            this.validation.state = "Employer State cannot be empty";
        else
            delete this.validation.state;
        this.notifyViewAboutChanges();
    }

    public onEmployerZipCodeChange = (e: React.FormEvent) => {
        this.zipCode = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (!this.zipCode.replaceAll(' ', ''))
            this.validation.zipCode = "Employer ZIP Code cannot be empty";
        else
            delete this.validation.zipCode;
        this.notifyViewAboutChanges();
    }

    public onSecurityQuestionChange = (e: React.FormEvent) => {
        this.questionId = +(e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        this.answer = '';
        if (+this.questionId == 0) {
            this.validation.question = "Security Question cannot be empty";
            delete this.validation.answer;
        }
        else
            delete this.validation.question;
        this.notifyViewAboutChanges();
    }

    public onSecurityQuestionAnswerChange = (e: React.FormEvent) => {
        this.answer = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (+this.questionId && !this.answer.replaceAll(' ', ''))
            this.validation.answer = "Answer cannot be empty";
        else
            delete this.validation.answer;
        this.notifyViewAboutChanges();
    }

    public onAlternativeEmailChange = (e: React.FormEvent) => {
        this.alternativeEmail = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (this.alternativeEmail && this.alternativeEmail.length > 0 && this.alternativeEmail == this.email)
            this.validation.alternativeEmail = "Alternative Email Cannot be equal to Email";
        else if (this.alternativeEmail && !FormValidator.isValidEmail(this.alternativeEmail))
            this.validation.alternativeEmail = "Alternative Email is not valid";
        else
            delete this.validation.alternativeEmail;
        this.notifyViewAboutChanges();
    }

    public onAlternativePhoneChange = (e: React.FormEvent) => {
        this.alternativePhone = (e as React.FormEvent<HTMLInputElement>).currentTarget.value;
        if (this.alternativePhone && !FormValidator.isValidRegistrationPhone(this.alternativePhone))
            this.validation.alternativePhone = "Alternative Phone is not valid";
        else
            delete this.validation.alternativePhone;
        this.notifyViewAboutChanges();
    }

    public onGlbaChange = (id: number) => {
        this.GLBAOptionId = id;
        this.notifyViewAboutChanges();
    }

    public onDppaChange = (id: number) => {
        this.DPPAOptionId = id;
        this.notifyViewAboutChanges();
    }

    public onCompanyIdChange = (id: number | undefined) => {
        this.companyId = id;
        if (id === undefined)
            this.validation.selectedCompany = 'Company can not be empty';
        else
            delete this.validation.selectedCompany;
        this.notifyViewAboutChanges();
    }

    private allRequiredFeildsAreNotEmpty = () => {
        return this.firstName?.replaceAll(' ', '') &&
            this.lastName?.replaceAll(' ', '') &&
            this.phone?.replaceAll(' ', '') &&
            this.answer?.replaceAll(' ', '') &&
            this.employerName?.replaceAll(' ', '') &&
            this.employerAddress?.replaceAll(' ', '') &&
            this.city?.replaceAll(' ', '') &&
            this.state?.replaceAll(' ', '') &&
            this.zipCode?.replaceAll(' ', '') &&
            this.companyId !== undefined;
    }

    private validateRegisterForm = () => {
        if (Object.keys(this.validation).length == 0 && this.allRequiredFeildsAreNotEmpty())
            this.formIsValid = true;
        else
            this.formIsValid = false;
    };

    private notifyViewAboutChanges = (): void => {

        this.validateRegisterForm();

        const data = {
            questionId: this.questionId,
            answer: this.answer,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            phone: this.phone,
            alternativeFirstName: this.alternativeFirstName,
            alternativeLastName: this.alternativeLastName,
            alternativeEmail: this.alternativeEmail && this.alternativeEmail.length > 0 ? this.alternativeEmail : null,
            alternativePhone: this.alternativePhone,
            secondaryEmail: this.secondaryEmail,
            managerName: this.managerName,
            employerName: this.employerName,
            employerAddress: this.employerAddress,
            city: this.city,
            state: this.state,
            zipCode: this.zipCode,
            DPPAOptionId: this.DPPAOptionId,
            GLBAOptionId: this.GLBAOptionId,
            validation: this.validation,
            isLoading: this.isLoading,
            isSuccess: this.isSuccess,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages,
            startTypePassword: this.startTypePassword,
            Questions: this.Questions,
            GLBAOptions: this.GLBAOptions,
            DPPAOptions: this.DPPAOptions,
            deactivateReason: this.deactivateReason,
            deactivateReasonInput: this.deactivateReasonInput,
            accountStatus: this.accountStatus,
            companyId: this.companyId,
            showChangeAccountStatus: this.showChangeAccountStatus,
            formIsValid: this.formIsValid,
            Companies: this.Companies,
            userEvents: this.userEvents,
            lastTosAcceptedDate: this.lastTosAcceptedDate
        };
        this.subject?.next({ topic: this.topic, data });
    };

    public attachSubject = (subject: Subject<any>, topicName: string): void => {
        this.topic = topicName;
        this.subject = subject;
    };
}
