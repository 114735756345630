import LocalStorageService from '../../../../presentation/utils/LocalStorageService';
import UserSession from '../structures/UserSessionModel';
import AuthListener from './AuthListener';

export default class AuthHolder {
    private isAuthorized: boolean;
    public isLoading: boolean;
    private authToken: string | null;
    private authListeners: AuthListener[];
    public firstName: string;
    public lastName: string;
    public sid: string;
    public email: string;
    public isAdmin: boolean;
    public canCreateSearchRequests: boolean;
    public termsAndConditionsAndPrivacyPolicyUpdated: boolean;

    public constructor() {
        this.authToken = '';
        this.firstName = '';
        this.lastName = '';
        this.sid = '';
        this.email = '';
        this.isAuthorized = false;
        this.isAdmin = false;
        this.canCreateSearchRequests = false;        
        this.isLoading = true;
        this.authListeners = [];
        this.termsAndConditionsAndPrivacyPolicyUpdated = false;
    }

    public onSignedIn(authToken: string | null): void {
        this.isAuthorized = true;
        this.authToken = authToken;
        this.notifyListeners();
    }

    public onSignedOut(): void {
        this.isAuthorized = false;
        this.authToken = '';
        this.notifyListeners();
        localStorage.removeItem("jwt");
        LocalStorageService.removeUserRole();
        window.location.href = '/';
    }

    /**
     * ToDo: declare user structure type and the below function to reflect the real data
     * @param user
     * @returns 
     */
    public onSessionLoad(user: UserSession): AuthHolder {
        this.isAuthorized = user.email != null;
        this.isLoading = false; 
        this.email = user.email;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
        this.sid = user.sid;
        this.isAdmin = user.isAdmin;
        this.canCreateSearchRequests = user.canCreateSearchRequests;
        this.termsAndConditionsAndPrivacyPolicyUpdated = user.termsAndConditionsAndPrivacyPolicyUpdated;
        // this.authToken = user.token
        return this;
    }

    public isUserAuthorized(): boolean {
        return this.isAuthorized;
    }

    /**
     * @throws {Error} if user is not authorized
    */
    public getAuthToken(): string | null {
        if (!this.isAuthorized) {
            throw new Error('User is not authorized');
        }

        return this.authToken;
    }
    public addAuthListener(authListener: AuthListener): void {
        this.authListeners.push(authListener);
    }

    public removeAuthListener(authListener: AuthListener): void {
        this.authListeners.splice(this.authListeners.indexOf(authListener), 1);
    }

    private notifyListeners(): void {
        this.authListeners.forEach((listener) => listener.onAuthChanged());
    }
}