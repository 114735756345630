import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import ChangePassword from "../auth/ChangePassword";
import GlbaDppaChangeRequest from "./GlbaDppaChangeRequest";
import SocialConnectionList from "./SocialConnectionList";
import UserInfo from "./UserInfo";
import UserViewPermissions from "./UserViewPermissions";
import useUser from "../../../infrastructure/hooks/useUser";
import UserSettingsViewModel from "../../view-model/usersettings/UserSettingsViewModel";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import UserSettingApi from "../../../data/usersettings/UserSettingApi";
import UserAccountModel from "../../../domain/entities/usersettings/UserProfileModel";
import { useDispatch } from "react-redux";
import setUserProfile from "../../../infrastructure/redux/actions/setUserProfile";
import { TailSpin } from "react-loader-spinner";
import Communication from "./Communication";

type UserSettingsProps = {
    defaultactiveTab: string;
}
const UserSettingsComponent: React.FC<UserSettingsProps> = ({ defaultactiveTab }) => {

    const [activeTab, setState] = useState(defaultactiveTab);
    const { user } = useUser();

    const [SuccessState, setSuccessState] = useState({
        dataIsLoaded: false,
        isLoading: true,
        isShowError: false
    });

    const dispatch = useDispatch();

    const { viewModel, subscription } = useViewModel(UserSettingsViewModel, [
        new UserSettingApi(new HttpClient()),
    ]);

    useEffect(() => {
        const subscriber = subscription.subscribe((d) =>
            setSuccessState({ ...SuccessState, ...d.data })
        );

        viewModel.getUserAccount().then(result => {
            if (result.isSuccess) {
                dispatch(setUserProfile(result.value as UserAccountModel || {}));
                setSuccessState({ ...SuccessState, dataIsLoaded: true, isLoading: false });
            }
        })

        return () => {
            subscriber.unsubscribe();
        };
    }, []);

    const changeUrl = (k: string | null): void => {
        toast.dismiss();
        setState(k ?? 'profile');
        let path = ''
        if (k == 'profile') { path = '/settings/profile' }
        if (k == 'change-password') { path = '/settings/security' }
        if (k == 'social-connection') { path = '/settings/social-connection' }
        if (k == 'permissions') { path = '/settings/permissions' }
        if (k == 'glba-dppa') { path = '/settings/glba-dppa' }
        if (k == 'communication') { path = '/settings/communication' }
        window.history.replaceState(null, "", path)
    };
    return (
        <div className="container">
            <Helmet>
                <title>Social E-Profiler - User Settings</title>
                <meta name="description" content="Social E-Profiler" />
            </Helmet>
            <div className="settings-div">
                <div className="header-title">
                    <h4 className="header-title-text">
                        Settings
                    </h4>
                </div>
                <div className="container">
                    {SuccessState.dataIsLoaded &&
                        <Tabs
                            id="controlled-tab-example"
                            activeKey={activeTab}
                            onSelect={(k) => changeUrl(k)}
                            className="tabs-div mb-3">
                            <Tab tabClassName="tab-item" eventKey="profile" title="Profile">
                                <UserInfo />
                            </Tab>
                            <Tab tabClassName="tab-item" eventKey="change-password" title="Security">
                                <ChangePassword />
                            </Tab>
                            <Tab tabClassName="tab-item" eventKey="social-connection" title="Connections">
                                <SocialConnectionList />
                            </Tab>
                            <Tab tabClassName="tab-item" eventKey="permissions" title="Permissions">
                                <UserViewPermissions />
                            </Tab>
                            <Tab tabClassName="tab-item" eventKey="glba-dppa" title="GLBA/DPPA">
                                <GlbaDppaChangeRequest />
                            </Tab>
                            <Tab tabClassName="tab-item" eventKey="communication" title="Communication">
                                <Communication />
                            </Tab>
                        </Tabs>
                    }
                </div>
                <TailSpin
                    wrapperClass="cover-spin"
                    visible={SuccessState.isLoading}
                    height={50}
                    width="50"
                    color="#FF5733"
                    ariaLabel="loading"
                />
                <Modal show={SuccessState.isShowError}>
                    <Modal.Body className="modal-card warning-modal-card error-modal">
                        <div>
                            <Row className="warning-modal-header">
                                <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                                <Col ><span className="cancel-modal-title">Server Error</span></Col>
                                <Col md="auto" className="close-modal-icon"><span onClick={() => setSuccessState({ ...SuccessState, isShowError: false })}>x</span></Col>
                            </Row>
                            <Row>
                                <p className="p-0 warning-modal-body">There was an error connecting to the server</p>
                            </Row>
                            <Row className="warning-modal-btn-row">
                                <Col className="p-0"><a onClick={() => setSuccessState({ ...SuccessState, isShowError: false })} className="btn warning-btn">Try Again Later</a></Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}
export default UserSettingsComponent;