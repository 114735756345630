import React, { useEffect, useState } from "react";
import { TableChangeState } from "react-bootstrap-table-next";
import { useLocation, useNavigate, useParams } from "react-router";
import { HttpClient } from "../../../infrastructure/utils/fetchInterceptor";
import SearchListTable from "../SearchListTable";
import useViewModel from "../../../infrastructure/hooks/useViewModel";
import UserSearchListViewModel from "../../view-model/search/UserSearchListViewModel";
import SearchApi from "../../../data/search/SearchApi";
import { useDispatch } from "react-redux";
import { setError } from "../../../infrastructure/redux/actions/error";
import BackArrowSvgIcon from "../svg-icons/BackArrow";
import { Modal } from "react-bootstrap";
import SearchDetails from "../../views/SearchDetails";
import UserAccountApi from "../../../data/admin/UserAccountApi";
import { setReRunSearch } from "../../../infrastructure/redux/actions/reRunSearch";
import SearchDetailsModel from "../../../domain/entities/search/SearchDetailsModel";


type UserSearchRequestsComponentState = {
    pageSize: number;
    pageNumber: number;
    totalCount: number;
    pageData: [];
    showAddModal: boolean;
    selectedItemId: number | null;
    searchText: string;
    isLoading: boolean;
    isShowError: boolean;
    errorMessages: string[];
    name: string;
    searchDetails: SearchDetailsModel | null;
  };

const UserSearchRequests: React.FC = () => {

    const { id } = useParams();
    const dispatch = useDispatch();
    const { viewModel, subscription } = useViewModel(UserSearchListViewModel, [
        new UserAccountApi(new HttpClient()),
        new SearchApi(new HttpClient())
    ]);
    const search = useLocation().search;
    viewModel.userId = id;

    const navigate = useNavigate();

    const [state, setState] = useState<UserSearchRequestsComponentState>({
        pageSize: viewModel.pageSize,
        pageNumber: viewModel.pageNumber,
        totalCount: viewModel.totalCount,
        pageData: viewModel.pageData,
        showAddModal: viewModel.showAddModal,
        selectedItemId: viewModel.selectedItemId,
        isLoading: viewModel.isLoading,
        isShowError: viewModel.isShowError,
        errorMessages: viewModel.errorMessages,
        searchText: viewModel.searchText,
        name: viewModel.name,
        searchDetails: viewModel.searchDetails
      });

      const getPageWrap = async (pageNumber: number) => {
        const result = await viewModel.getPage(--pageNumber);
        if (result.statusCode != 200) {
          dispatch(
            setError({
              errorCode: result.statusCode,
            })
          );
        }
      };

      const onTableChange = (type: any, newState: TableChangeState<any>) => {
        if (type === "pagination"){
          viewModel.getPage(--newState.page);
          let oldUrl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
            oldUrl += "?pg=" + ++newState.page;
          window.history.pushState({ path: oldUrl }, "", oldUrl);
        }
        else if (type === "sort"){
          viewModel.updateSorting(newState.sortField, newState.sortOrder);
        }
      };
    
      const onPageSizeChange = (pageSize: number, page: number) => {
        viewModel.pageSize = pageSize;
        getPageWrap(page);
      };

      useEffect(() => {
        const subscriber = subscription.subscribe((d: any) => {
          setState({
            ...state,
            ...d.data,
          });
        });
        viewModel.init();
        const urlPageNumber = new URLSearchParams(search).get("pg") || "";
        
        getPageWrap(parseInt(urlPageNumber.toString()) || 1);    
        return () => {
          subscriber.unsubscribe();
        };
      }, []);

    const rerunSearch = async (id: number) => {
        await viewModel.getSearchDetails(id);
        dispatch(setReRunSearch(viewModel.searchDetails as unknown as ReRunSearchState));
        navigate('/');
    }

    return (
        <div className="container">
            <div className="admin-page-title"> <a href="/admin/user"> <BackArrowSvgIcon/> </a> {state.name}&apos;s Search Requests</div>
              <SearchListTable
                resultsUrl={`/admin/user/${id}/search-request`}
                isLoading={state.isLoading} 
                pageData={state.pageData}
                onOpenSearchDetails={(id) => viewModel.openAddModal(id)}
                page={state.pageNumber + 1}
                sizePerPage={state.pageSize}
                totalSize={state.totalCount}
                onPageSizeChange={onPageSizeChange}
                onTableChange={onTableChange}
                onSearch={viewModel.onSearchChanged}
                onRerunSearch={rerunSearch}
              />
              <Modal
                show={state.showAddModal}
                onHide={viewModel.closeAddModal}
                size="lg">
                <SearchDetails
                    data={state.searchDetails}
                    onCloseModal={viewModel.closeAddModal}
                    onRerunSearch={rerunSearch}
                />
              </Modal>
            </div>
    );
};

export default UserSearchRequests;
