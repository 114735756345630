import type CaseRepository from '../../domain/repositories/cases/ICaseRepository';
import Result from '../../domain/common/Result';
import CaseModel from '../../domain/entities/cases/CaseModel';
import { HttpClient } from '../../infrastructure/utils/fetchInterceptor';
import PaginationRequestModel from '../../domain/entities/generic/PaginationRequestModel';

export default class CaseApi implements CaseRepository {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }
    add(object: CaseModel): Promise<Result<string>> {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };

        return this.httpClient.httpFetch(`/api/case/add`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<any>(await res.json());
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    update(object: CaseModel): Promise<Result<string>> {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };

        return this.httpClient.httpFetch(`/api/case/update`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<any>(await res.json());
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message,500);
        });
    }

    get(object: PaginationRequestModel): Promise<Result<any>> {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(object)
        };

        return this.httpClient.httpFetch(`/api/case/listpage`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<any>(await res.json());
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message,500);
        });
    }

    caseDetails(caseId: string): Promise<Result<any>> {
        const requestOptions = {
            method: 'GET'
        };

        return this.httpClient.httpFetch(`/api/case/get?caseId=${caseId}`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<any>(await res.json());
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message,500);
        });
    }
}
