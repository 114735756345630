import Result from '../../domain/common/Result';
import { HttpClient } from '../../infrastructure/utils/fetchInterceptor';
import UserAccountModel from '../../domain/entities/admin/models/UserAccountModel';
import AccountStatusModel from '../../domain/entities/admin/models/AccountStatusModel';
import PageResult from '../../domain/common/PageResult';
import AdminUsersPaginationQuery from '../../domain/entities/admin/models/AdminUsersPaginationQuery';
import HelperMethods from '../../presentation/utils/HelperMethods';
import IUserAccountRepo from '../../domain/repositories/admin/IUserAccountRepo';
import UserInfoModel from '../../domain/entities/admin/models/UserInfoModel';


export default class UserAccountApi implements IUserAccountRepo {
    httpClient: HttpClient;

    constructor(httpClient: HttpClient) {
        this.httpClient = httpClient;
    }

    ListAccountStatus(): Promise<Result<any>> {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        return this.httpClient.httpFetch(`/api/GenericData/ListAccountStatus`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<AccountStatusModel[]>(await res.json());
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    ListUsersAccounts(model: AdminUsersPaginationQuery): Promise<any> {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };

        return this.httpClient.httpFetch('/api/Admin/UserAccount' + HelperMethods.toQueryString(model), requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<PageResult<UserAccountModel>>(await res.json());
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    GetUserInfo(id: string): Promise<Result<UserInfoModel | string>> {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/Admin/UserAccount/${id}`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.OkWithValue<UserInfoModel>(await res.json());
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    UpdateUserInfo(model: UserInfoModel): Promise<Result<string>> {
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(model)
        };

        return this.httpClient.httpFetch(`/api/Admin/UserAccount`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    ActivateUserAccount(id: string): Promise<Result<string>> {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
        };

        return this.httpClient.httpFetch(`/api/Admin/UserAccount/${id}/Activate`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

    DeactivateUserAccount(id: string, reason: string): Promise<Result<string>> {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(reason)
        };

        return this.httpClient.httpFetch(`/api/Admin/UserAccount/${id}/Deactivate`, requestOptions).then(async res => {
            if (res.status === 200)
                return Result.Ok();
            return Result.Fail('Server Error', res.status);
        }).catch(e => {
            return Result.Fail(e.message, 500);
        });
    }

}
