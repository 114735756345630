import UserSettingRepository from "../../../domain/repositories/usersettings/IUserSettingRepository";
import IBaseViewModel from "../IBaseViewModel";
import { Subject } from "rxjs";
import UserSearchRequestsViewPermissionRequestModel from "../../../domain/entities/usersettings/UserSearchRequestsViewPermissionRequestModel";
import FormValidator from "../../utils/FormValidator";
import { toast } from "react-toastify";
import { TableChangeState } from "react-bootstrap-table-next";

export default class ViewPermissionsViewModel implements IBaseViewModel {

    public pageNumber: number;
    public pageSize: number;
    public totalCount: number;

    public emailFormValues: string[];
    public email: string[];
    public data: UserSearchRequestsViewPermissionRequestModel[];
    public pageData: UserSearchRequestsViewPermissionRequestModel[];
    public validation: any;

    public isLoading: boolean;
    public isShowError: boolean;
    public errorMessages: string[];
    public isSuccess: boolean;

    private topic?: string;
    private subject?: Subject<unknown>;
    private userSettingRepository: UserSettingRepository;

    public constructor(userSettingRepository: UserSettingRepository) {
        this.pageNumber = 0;
        this.pageSize = 10;
        this.totalCount = 0;

        this.emailFormValues = [""];
        this.email = [""];
        this.pageData = [];
        this.data = [];
        this.validation = [];
        
        this.isLoading = false;
        this.isShowError = false;
        this.errorMessages = [];
        this.isSuccess = false;

        this.userSettingRepository = userSettingRepository;
    }

    
    public getPage = async (): Promise<void> => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();

            const result = await this.userSettingRepository.GetSearchRequestsViewPermissionRequest();
            this.isShowError = !result.isSuccess;

            if (result.isSuccess && result.value && result.value.length > 0) {
                this.data = result.value;
                this.totalCount = this.data.length;
                this.updateCurrentPageData(1, 10);
            }
            else if (!result.isSuccess)
                this.errorMessages.push(result.error);

            this.isLoading = false;
            this.notifyViewAboutChanges();
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
        }
    };

    private validateDuplicates(list: string[], element: string) {
        const checkDuplicates = list.filter(e => e == element);
        if (checkDuplicates?.length <= 1)
            return -1;
        const duplicateIndex = checkDuplicates[checkDuplicates.length - 1];
        return duplicateIndex;
    }

    public validateEmails() {
        this.validation.email = [];
        let isShowError = false;
        if (this.email) {
            let i = 0;
            this.validation.email = [];
            this.email.forEach((element) => {
                if (element && !FormValidator.isValidEmail(element)) {
                    isShowError = true;
                    this.validation.email[i] = "Email not valid";
                }

                const duplicateIndex = this.validateDuplicates(this.emailFormValues, element);
                if (duplicateIndex != -1) {
                    this.validation.email[this.emailFormValues.lastIndexOf(duplicateIndex)] = "Duplicated email";
                    isShowError = true;
                }
                i++;
            });
        }
    }

    public onEmailQueryAdded(): void {
        let error = false;
        this.validation.email = [];
        if (this.emailFormValues) {
            let index = 0;
            this.emailFormValues.forEach((element) => {
                if (!element || !FormValidator.isValidEmail(element)) {
                    error = true;
                    this.validation.email[index] = "Email not valid";
                    this.notifyViewAboutChanges();
                }

                const duplicateIndex = this.validateDuplicates(this.emailFormValues, element);
                if (duplicateIndex != -1) {
                    this.validation.email[this.emailFormValues.lastIndexOf(duplicateIndex)] = "Duplicated email";
                    error = true;
                    this.notifyViewAboutChanges();
                }
                index++;
            });
        }
        if (!error) {
            this.emailFormValues.push("");
            this.notifyViewAboutChanges();
        }
    }

    public onEmailQueryChanged(email: string, index: number): void {
        if (!this.validation.email) this.validation.email = [];
        if (!email || !FormValidator.isValidEmail(email)) {
            this.validation.email[index] = "Email not valid";
        } else {
            this.validation.email[index] = "";
        }
        this.emailFormValues[index] = email;
        this.email[index] = email;
        this.validateEmails();
        this.notifyViewAboutChanges();
    }

    public onEmailQueryRemoved(index: number): void {
        this.email.splice(index, 1);
        this.emailFormValues.splice(index, 1);
        this.notifyViewAboutChanges();
    }

    public onTableChange = (type: any, newState: TableChangeState<any>) => this.updateCurrentPageData(newState.page, newState.sizePerPage);

    public onPageSizeChange = (pageSize: number, page: number) => this.updateCurrentPageData(page, pageSize);

    public updateCurrentPageData = (pageNumber: number, pageSize: number) => {

        const pageIndex = pageNumber - 1;
        const currentPageStartIndex = pageIndex * pageSize;
        const currentPageData = this.data.slice(currentPageStartIndex, currentPageStartIndex + pageSize);

        this.pageNumber = pageIndex;
        this.pageSize = pageSize;
        this.pageData = currentPageData;

        this.notifyViewAboutChanges();
    }

    public submit = async () => {
        
        let emails: string = this.email[0];
        this.email.slice(1).forEach(e => emails += `, ${e}`);

        this.isLoading = true;
        this.notifyViewAboutChanges();
        const r = await this.userSettingRepository.AddSearchRequestsViewPermissionRequest(emails);
        this.isLoading = false;

        this.isShowError = !r.isSuccess;
        r.error ? this.errorMessages.push(r.error) : null;

        if (r.isSuccess) {
            this.email = [""];
            this.emailFormValues = [""];
            this.data = ([{ requestedUsersEmails: emails, requestStatus: 'Pending' }]).concat(this.data);
            this.updateCurrentPageData(1, this.pageSize);
            this.notify();
        }

        this.notifyViewAboutChanges();
    }

    private notify = () => {
        toast.success("A request has been submitted to the Admin", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            closeButton: false,
            theme: "dark",
            progress: undefined,
            bodyClassName: "toast-message",
        });
    };
   
    public notifyViewAboutChanges = (): void => {
        const data = {
            pageSize: this.pageSize,
            pageNumber: this.pageNumber,
            totalCount: this.totalCount,
            pageData: this.pageData,
            isLoading: this.isLoading,
            isSuccess: this.isSuccess,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages,
            emailFormValues: this.emailFormValues,
            validation: this.validation
        };
        this.subject?.next({ topic: this.topic, data });
    };

    public attachSubject = (subject: Subject<any>, topicName: string): void => {
        this.topic = topicName;
        this.subject = subject;
    };
}
