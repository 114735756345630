import { SET_Update_Requests_Count } from "../actions/actionTypes";

const initialState: number | null = null;

const reducer = (state: number | null = initialState, action: ActionType) => {
    if (action.type == SET_Update_Requests_Count)
        return action.payload;
    else
        return state;
}

export default reducer;