import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { TailSpin } from "react-loader-spinner";
import AuthApi from "../../data/auth/AuthApi";
import RegistrationDataApi from "../../data/registrationData/RegistrationDataApi";
import DPPAOption from "../../domain/entities/usersettings/DPPAOption";
import GLBAOption from "../../domain/entities/usersettings/GLBAOption";
import Question from "../../domain/entities/usersettings/Question";
import useViewModel from "../../infrastructure/hooks/useViewModel";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import AcceptTerms from "../components/registration/AcceptTerms";
import AccountInfo from "../components/registration/AccountInfo";
import GlbaDppa from "../components/registration/GlbaDppa";
import SuccessfulRegistrationRequest from "../components/registration/SuccessfulRegistrationRequest";
import RegisterViewModel from "../view-model/auth/RegisterViewModel";


export type RegisterComponentState = {
    questionId: number;
    answer: string
    isTermsOfServiceChecked: boolean;
    isPrivacyPolicyChecked: boolean;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    alternativeFirstName: string | null;
    alternativeLastName: string | null;
    alternativeEmail: string | null;
    alternativePhone: string | null;
    secondaryEmail: string | null;
    managerName: string | null;
    employerName: string;
    employerAddress: string;
    city: string;
    state: string;
    zipCode: string;
    acceptedToSPPVer: string;
    DPPAOptionId: number;
    GLBAOptionId: number;
    validation: any;
    isLoading: boolean;
    isShowError: boolean;
    errorMessages: string[];
    isSuccess: boolean;
    formIsValid: boolean;
    showCancelModal: boolean;
    showGlbaDppaWarningModal: boolean;
    glbaDppaSelectedOptionWarning: any;

    GLBAOptions: GLBAOption[];
    DPPAOptions: DPPAOption[];
    Questions: Question[];
};


const Register: React.FC = () => {

    const { viewModel, subscription } = useViewModel(RegisterViewModel, [
        new AuthApi(new HttpClient()), new RegistrationDataApi(new HttpClient())
    ]);

    const [state, setState] = useState<RegisterComponentState>({
        isTermsOfServiceChecked: false,
        isPrivacyPolicyChecked: false,
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        alternativeFirstName: "",
        alternativeLastName: "",
        alternativeEmail: "",
        alternativePhone: "",
        secondaryEmail: "",
        managerName: "",
        employerName: "",
        employerAddress: "",
        city: "",
        state: "",
        zipCode: "",
        acceptedToSPPVer: "",
        DPPAOptionId: 0,
        GLBAOptionId: 0,

        isSuccess: false,
        validation: {},
        isLoading: false,
        isShowError: false,
        errorMessages: [],
        questionId: 0,
        answer: "",
        DPPAOptions: [],
        GLBAOptions: [],
        Questions: [],
        formIsValid: false,
        showCancelModal: false,
        showGlbaDppaWarningModal: false,
        glbaDppaSelectedOptionWarning: ''
    });

    useEffect(() => {
        const subscriber = subscription.subscribe((d) =>
            setState({ ...state, ...d.data })
        );

        viewModel.getData();

        return () => {
            subscriber.unsubscribe();
        };

    }, []);

    const [displayState, setDisplayState] = useState({ acceptTerms: true, accountInfo: false, glbaDppa: false });

    const onAcceptTermsNext = () => setDisplayState({ acceptTerms: false, accountInfo: true, glbaDppa: false });
    const onAccountInfoNext = () => setDisplayState({ acceptTerms: false, accountInfo: false, glbaDppa: true });
    const onAccountInfoPrev = () => setDisplayState({ acceptTerms: true, accountInfo: false, glbaDppa: false });
    const onGlbaDppaPrevious = () => setDisplayState({ acceptTerms: false, accountInfo: true, glbaDppa: false });

    const introStatement = <>
        <div className="row registration-title"><span className="p-0">Registration</span></div>
        <div className="row intro">Fill out the required fields in the registration form below so you can get access to your system. Registration info review usually be within 24 hours.</div>
    </>;

    return <>
        {!state.isSuccess && 
            <>
                <div className={!displayState.acceptTerms ? 'd-none' : ''} ><AcceptTerms state={state} viewModel={viewModel} introStatement={introStatement} onNext={onAcceptTermsNext} /></div>
                <div className={!displayState.accountInfo ? 'd-none' : ''}><AccountInfo state={state} viewModel={viewModel} introStatement={introStatement} onNext={onAccountInfoNext} onPrevious={onAccountInfoPrev} /></div>
                <div className={!displayState.glbaDppa ? 'd-none' : ''}><GlbaDppa state={state} viewModel={viewModel} introStatement={introStatement} onPrevious={onGlbaDppaPrevious} /></div>
                <Modal show={state.showCancelModal}>
                    <Modal.Body className="modal-card cancel-modal-card">
                        <div className="cancel-modal">
                            <Row className="cancel-modal-header">
                                <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                                <Col ><span className="cancel-modal-title">Cancel Registration?</span></Col>
                                <Col md="auto" className="close-modal-icon"><span onClick={viewModel.hideCancelModal}>x</span></Col>
                            </Row>
                            <Row>
                                <p className="cancel-modal-body p-0">If you cancel your registration now you will lose your data</p>
                            </Row>
                            <Row className="cancel-modal-btn-row">
                                <Col md="auto" className="p-0"><a onClick={viewModel.hideCancelModal} className="btn previous-btn">No, continue registration</a></Col>
                                <Col className="p-0"><a onClick={viewModel.cancelRegistration} className="btn next-btn">Yes, cancel registration</a></Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
                <TailSpin
                wrapperClass="cover-spin"
                visible={state.isLoading}
                height={50}
                width="50"
                color="#FF5733"
                ariaLabel="loading"
                />
                <Modal show={state.isShowError}>
                    <Modal.Body className="modal-card warning-modal-card error-modal registration-error-modal">
                        <div>
                            <Row className="warning-modal-header">
                                <Col md="auto" className="p-0" ><img src="/images/exclamation-circle.svg" /></Col>
                                <Col ><span className="cancel-modal-title">Server Error</span></Col>
                                <Col md="auto" className="close-modal-icon"><span onClick={viewModel.resetIsShowError}>x</span></Col>
                            </Row>
                        <Row> {viewModel.errorMessages.map(e => <p key={e} className="p-0 warning-modal-body m-2">{e}</p>) } </Row>
                            <Row className="warning-modal-btn-row">
                                <Col className="p-0"><a onClick={viewModel.resetIsShowError} className="btn warning-btn">Try Again Later</a></Col>
                            </Row>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        }
        {state.isSuccess && !state.isShowError && <SuccessfulRegistrationRequest />}
    </>
    
};

export default Register;
