import React, { useEffect, useState } from "react";
import { TailSpin } from "react-loader-spinner";
import SearchListViewModel from "../view-model/search/SearchListViewModel";
import { Helmet } from "react-helmet";
import ErrorAlert from "../components/ErrorAlert";
import { TableChangeState } from "react-bootstrap-table-next";
import useViewModel from "../../infrastructure/hooks/useViewModel";
import SearchApi from "../../data/search/SearchApi";
import { HttpClient } from "../../infrastructure/utils/fetchInterceptor";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setError } from "../../infrastructure/redux/actions/error";
import SearchDetails from "./SearchDetails";
import { Modal } from "react-bootstrap";
import SearchListTable from "../components/SearchListTable";
import { setReRunSearch } from "../../infrastructure/redux/actions/reRunSearch";
import SearchDetailsModel from "../../domain/entities/search/SearchDetailsModel";

type SearchListComponentState = {
  pageSize: number;
  pageNumber: number;
  totalCount: number;
  pageData: [];
  showAddModal: boolean;
  selectedItemId: number | null;
  searchText: string;
  isLoading: boolean;
  isShowError: boolean;
  errorMessages: string[];
  searchDetails: SearchDetailsModel | null;
};

const SearchList: React.FC = () => {
  const dispatch = useDispatch();
  const { viewModel, subscription } = useViewModel(SearchListViewModel, [
    new SearchApi(new HttpClient()),
  ]);
  const search = useLocation().search;
  const caseId = new URLSearchParams(search).get("caseid");
  const navigate = useNavigate();

  const [state, setState] = useState<SearchListComponentState>({
    pageSize: viewModel.pageSize,
    pageNumber: viewModel.pageNumber,
    totalCount: viewModel.totalCount,
    pageData: viewModel.pageData,
    showAddModal: viewModel.showAddModal,
    selectedItemId: viewModel.selectedItemId,
    isLoading: viewModel.isLoading,
    isShowError: viewModel.isShowError,
    errorMessages: viewModel.errorMessages,
    searchText: viewModel.searchText,
    searchDetails: viewModel.searchDetails
  });

  const getPageWrap = async (pageNumber: number) => {
    if (caseId) {
      viewModel.onCaseIdQueryChanged(caseId);
      const result = await viewModel.getCaseDetails();
      if (result.statusCode != 200) {
        dispatch(
          setError({
            errorCode: result.statusCode,
          })
        );
      }
    }
    const result = await viewModel.getPage(--pageNumber);
    if (result.statusCode != 200) {
      dispatch(
        setError({
          errorCode: result.statusCode,
        })
      );
    }
  };

  useEffect(() => {
    const subscriber = subscription.subscribe((d: any) => {
      setState({
        ...state,
        ...d.data,
      });
    });
    const urlPageNumber = new URLSearchParams(search).get("pg") || "";

    getPageWrap(parseInt(urlPageNumber.toString()) || 1);

    return () => {
      subscriber.unsubscribe();
    };
  }, []);


  const onTableChange = (type: any, newState: TableChangeState<any>) => {
    if (type === "pagination"){
      viewModel.getPage(--newState.page);
      let oldUrl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      if (caseId) {
        oldUrl += `?caseid=${caseId}&pg=${++newState.page}`;
      } else {
        oldUrl += "?pg=" + ++newState.page;
      }
      window.history.pushState({ path: oldUrl }, "", oldUrl);
    }
    else if (type === "sort"){
      viewModel.updateSorting(newState.sortField, newState.sortOrder);
    }
  };

  const onPageSizeChange = (pageSize: number, page: number) => {
    viewModel.pageSize = pageSize;
    getPageWrap(page);
  };

    const rerunSearch = async (id: number) => {
        await viewModel.getSearchDetails(id);
        dispatch(setReRunSearch(viewModel.searchDetails as unknown as ReRunSearchState));
        navigate('/');
    }

  return (
    <div className="container">
      <Helmet>
        <title>Social E-Profiler - Search List</title>
        <meta name="description" content="Social E-Profiler" />
      </Helmet>
      {state.isShowError && (
        <div className="row mt-5">
          <ErrorAlert
            headerMessage="ListingErrorHeaderMessage"
            bodyMessage="ListingErrorBodyMessage"
          />
        </div>
      )}
      {!state.isShowError && (
        <div className="row mt-5">
          {viewModel.caseDetailsName && (
            <div className="breadcrumb-div listing">
              <a href="/case" className="breadcrumb-item active">
                Cases
              </a>
              <p className="breadcrumb-seperator-item">/</p>
              <p className="breadcrumb-item">{viewModel.caseDetailsName}</p>
            </div>
          )}
          <div className="row title-div">
            <h2 className="table-page-title title mx-3">Search History </h2>
            <a
              className="add-btn"
              href={
                "/" +
                (viewModel.caseId ? "?caseid=" + viewModel.caseId : "")
              }
            >
              <span className="add-btn-content">
                <p className="add-btn-text">New Search</p>
                <img src="/images/add.svg" className="add-btn-icon" alt="" />
              </span>
            </a>
          </div>

          {
            <SearchListTable 
              resultsUrl="/search-result"
              isLoading={state.isLoading} 
              pageData={state.pageData}
              onOpenSearchDetails={(id) => viewModel.openAddModal(id)}
              page={state.pageNumber + 1}
              sizePerPage={state.pageSize}
              totalSize={state.totalCount}
              onPageSizeChange={onPageSizeChange}
              onTableChange={onTableChange}
              onSearch={viewModel.onSearchChanged}
              onRerunSearch={rerunSearch}/>
          }
          <Modal show={state.showAddModal} onHide={viewModel.closeAddModal} size="lg">
            <div className="searc-details-modal">
              <Modal.Body>
                <SearchDetails data={state.searchDetails} onCloseModal={viewModel.closeAddModal} onRerunSearch={rerunSearch}/>
              </Modal.Body>
            </div>
          </Modal>
        </div>
          )}
          <TailSpin
              wrapperClass="cover-spin"
              visible={state.isLoading}
              height={50}
              width="50"
              color="#FF5733"
              ariaLabel="loading"
          />
    </div>
  );
};
export default SearchList;
