import { Subject } from "rxjs";
import IBaseViewModel from "../IBaseViewModel";
import Result from "../../../domain/common/Result";
import { TableChangeState } from "react-bootstrap-table-next";
import PendingRequestModel from "../../../domain/entities/admin/models/PendingRequestModel";
import PendingRequestType from "../../../domain/entities/admin/enums/PendingRequestType";
import PaginationQuery from "../../../domain/common/PaginationQuery";
import IPendingUserRequestRepo from "../../../domain/repositories/admin/IPendingUserRequestRepo";

export default class PendingRequestsViewModel implements IBaseViewModel {
    public pendingRequests: PendingRequestModel[];

    public selectedIndex: number;
    public pageIndex: number;
    public pageSize: number;
    public totalCount: number;

    public isLoading: boolean;
    public isShowError: boolean;
    public errorMessages: string[];
    public subject?: Subject<any>;
    public topic?: string;
    public selectedRequest: PendingRequestModel;
    public isShowGlbaDppaDetailsModal: boolean;
    public isShowViewPermissionDetailsModal: boolean;
    public isShowApprovalModal: boolean;
    public isShowDenialModal: boolean;
    public ApprovedDeniedRow: PendingRequestModel;
    private pendingUserRequestRepo: IPendingUserRequestRepo;


    public constructor(pendingUserRequestRepo: IPendingUserRequestRepo) {
        this.pendingRequests = [];
        this.selectedIndex = 0;
        this.pageIndex = 0;
        this.pageSize = 10;
        this.totalCount = 0;
        this.isLoading = false;
        this.isShowError = false;
        this.errorMessages = [];
        this.pendingUserRequestRepo = pendingUserRequestRepo;
        this.selectedRequest = {} as PendingRequestModel;
        this.isShowGlbaDppaDetailsModal = false;
        this.isShowViewPermissionDetailsModal = false;
        this.isShowApprovalModal = false;
        this.isShowDenialModal = false;
        this.ApprovedDeniedRow = {} as PendingRequestModel;
    }

    public hideApprovalModal = () => {
        this.isShowApprovalModal = false;
        this.notifyViewAboutChanges();
    }

    public hideDenialModal = () => {
        this.isShowDenialModal = false;
        this.notifyViewAboutChanges();
    }

    public showApprovalModal = (row: PendingRequestModel) => {
        this.ApprovedDeniedRow = row;
        this.isShowApprovalModal = true;
        this.notifyViewAboutChanges();
    }

    public showDenialModal = (row: PendingRequestModel) => {
        this.ApprovedDeniedRow = row;
        this.isShowDenialModal = true;
        this.notifyViewAboutChanges();
    }

    public onConfirmApproval = () => {
        this.isShowApprovalModal = false;
        return this.ApproveRequest();
    }

    public onConfirmDenial = () => {
        this.isShowDenialModal = false;
        return this.DenyRequest();
    }

    public onEmailClick = (selectedRow: PendingRequestModel) => {
        this.selectedRequest = selectedRow;
        this.isShowViewPermissionDetailsModal = selectedRow.requestType == PendingRequestType.ViewingOtherUsers;
        this.isShowGlbaDppaDetailsModal = selectedRow.requestType == PendingRequestType.GlbaDppaPermissions;
        this.notifyViewAboutChanges();
    }

    public hideGlbaDppaDetailsModal = () => {
        this.isShowGlbaDppaDetailsModal = false;
        this.notifyViewAboutChanges();
    }

    public hideViewPermissionDetailsModal = () => {
        this.isShowViewPermissionDetailsModal = false;
        this.notifyViewAboutChanges();
    }

    public ApproveRequest = async () => {
        try {
            this.isLoading = true;
            this.isShowApprovalModal = false;
            this.notifyViewAboutChanges();
            const id = this.ApprovedDeniedRow.id;
            const result = this.ApprovedDeniedRow.requestType == PendingRequestType.ViewingOtherUsers ?
                            await this.pendingUserRequestRepo.ApproveViewRequest(id) : this.ApprovedDeniedRow.requestType == PendingRequestType.GlbaDppaPermissions ?
                            await this.pendingUserRequestRepo.approveGlbaDppaChangeRequest(id) : Result.Fail("Unknown request type");
            this.isLoading = false;
            this.isShowError = !result.isSuccess;
            this.errorMessages.push(result.error);
            if (result.isSuccess) {
                this.pendingRequests = this.pendingRequests.filter(r => !(r.id == id && r.requestType == this.ApprovedDeniedRow.requestType));
            }
            this.notifyViewAboutChanges();
            return result;
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            this.notifyViewAboutChanges();
            return Result.Fail(e.message, 500);
        }
    }

    public DenyRequest = async () => {
        try {
            this.isLoading = true;
            this.isShowDenialModal = false;
            this.notifyViewAboutChanges();
            const id = this.ApprovedDeniedRow.id;
            const result = this.ApprovedDeniedRow.requestType == PendingRequestType.ViewingOtherUsers ?
                            await this.pendingUserRequestRepo.DenyViewRequest(id) : this.ApprovedDeniedRow.requestType == PendingRequestType.GlbaDppaPermissions ?
                            await this.pendingUserRequestRepo.denyGlbaDppaChangeRequest(id) : Result.Fail("Unknown request type");
            this.isLoading = false;
            this.isShowError = !result.isSuccess;
            this.errorMessages.push(result.error);
            if (result.isSuccess) {
                this.pendingRequests = this.pendingRequests.filter(r => !(r.id == id && r.requestType == this.ApprovedDeniedRow.requestType));
            }
            this.notifyViewAboutChanges();
            return result;
        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            return Result.Fail(e.message, 500);
        }
    }


    public getData = async () => {
        try {
            this.isLoading = true;
            this.notifyViewAboutChanges();
            const model = {} as PaginationQuery;
            model.PageNum = this.pageIndex;
            model.PageSize = this.pageSize;
            const pendingRequestsResult = await this.pendingUserRequestRepo.getPendingUserRequests(model);

            if (pendingRequestsResult.isSuccess) {

                this.pendingRequests = pendingRequestsResult.value?.pageData ?? [];
                this.totalCount = pendingRequestsResult.value?.totalCount ?? 0;
            }
            this.isLoading = false;
            this.isShowError = !pendingRequestsResult.isSuccess;
            pendingRequestsResult.error ? this.errorMessages.push(pendingRequestsResult.error) : null;
            this.notifyViewAboutChanges();

        } catch (e: any) {
            this.isLoading = false;
            this.errorMessages.push(e.message);
            this.isShowError = true;
            return Result.Fail(e.message, 500);
        }
    };

    private notifyViewAboutChanges = (): void => {
        const data = {
            pendingRequests: this.pendingRequests,
            selectedIndex: this.selectedIndex,
            pageIndex: this.pageIndex,
            pageSize: this.pageSize,
            totalCount: this.totalCount,
            isLoading: this.isLoading,
            isShowError: this.isShowError,
            errorMessages: this.errorMessages,
            selectedRequest: this.selectedRequest,
            isShowGlbaDppaDetailsModal: this.isShowGlbaDppaDetailsModal,
            isShowViewPermissionDetailsModal: this.isShowViewPermissionDetailsModal,
            isShowApprovalModal: this.isShowApprovalModal,
            isShowDenialModal: this.isShowDenialModal
        };
        this.subject?.next({ topic: this.topic, data });
    };

    attachSubject(subject: Subject<any>, topicName: string): void {
        this.subject = subject;
        this.topic = topicName;
    }

    public onTableChange = (type: any, newState: TableChangeState<any>) => this.updateCurrentPageData(newState.page, newState.sizePerPage);

    public onPageSizeChange = (pageSize: number, page: number) => this.updateCurrentPageData(page, pageSize);

    public updateCurrentPageData = (pageNumber: number, pageSize: number) => {

        this.pageIndex = pageNumber - 1;
        this.pageSize = pageSize;
        this.notifyViewAboutChanges();
        this.getData();
    }
}
